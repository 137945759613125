import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {ConfigsAPI} from 'api/configs';
import {WorkforceManagementGapMappings} from 'types/api/backend-py3/definitions/configs';
import {Domains} from 'types/domains';
import {getDomain} from 'utils/domain';

const currentDomain = getDomain();

export function useStaffAbsencesMappingConfig() {
    return useQuery<WorkforceManagementGapMappings['body']>({
        queryKey: ['resources.staffAbsencesMappingConfig'],
        queryFn: () => {
            return ConfigsAPI.request(['WORKFORCE_MANAGEMENT_ABSENCE_GAP_MAPPING'])
                .then(response => {
                    if (!response.data.configs) {
                        return {} as WorkforceManagementGapMappings['body'];
                    }

                    const config = response.data.configs[0] as WorkforceManagementGapMappings;

                    return config.body;
                })
                .catch(() => {
                    console.error('Config of absences Staff load with error');

                    return {} as WorkforceManagementGapMappings['body'];
                });
        },
        gcTime: Infinity,
        refetchOnMount: false,
    });
}

export function useStaffAbsencesForDomains() {
    const {data, ...rest} = useStaffAbsencesMappingConfig();

    const domains = useMemo<Undefinable<Domains[]>>(() => {
        return data ? Object.keys(data) as Domains[] : data;
    }, [data]);

    return useMemo(() => ({
        data: domains,
        ...rest,
    }), [domains, rest]);
}

export function useStaffAbsencesEnabled() {
    const {data: domainsWithStaffAbsences, isLoading} = useStaffAbsencesForDomains();

    return Boolean(!isLoading && domainsWithStaffAbsences?.includes(currentDomain));
}
