import {ConfigResponse} from './configs';

export enum Languages {
    Ru = 'ru',
    En = 'en',
    Ka = 'ka',
    Az = 'az',
}

export interface LanguageInfo {
    lang: string; // ru | en | ...
    isActive: boolean;
}

export type LanguageInfoList = LanguageInfo[];

export type Response = ConfigResponse<'WFM_FRONTEND_UI_LANGUAGES', LanguageInfoList>;
