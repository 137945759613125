import React, {memo, useEffect, useState} from 'react';
import {createPortal} from 'react-dom';

import {ItemPropsType} from '../types';

import BugReport from './components/BugReport';
import {Props} from './types';

const BugReportWrapper: Props = ({isCollapsed, children}) => {
    const [container] = useState(() => {
        return document.createElement('div');
    });

    useEffect(() => {
        document.body.appendChild(container);
        return () => {
            document.body.removeChild(container);
        };
    }, [container]);

    return createPortal(<BugReport isCollapsed={isCollapsed}>{children}</BugReport>, container);
};

export default memo<ItemPropsType>(BugReportWrapper);
