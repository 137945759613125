// https://learn.javascript.ru/cookie

export function getCookie(name: string): string | undefined {
    const matches = document.cookie.match(new RegExp(
        // eslint-disable-next-line
        '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)',
    ));

    return matches ? decodeURIComponent(matches[1]) : undefined;
}

type SetCookieOptions = {
    path?: string;
    expires?: string | Date;
} & Record<string, unknown>;

export function setCookie(name: string, value: string, options: SetCookieOptions = {}): void {
    const opts: SetCookieOptions = {
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options,
    };

    if (opts.expires instanceof Date) {
        opts.expires = opts.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (const optionKey in opts) {
        updatedCookie += '; ' + optionKey;
        const optionValue = opts[optionKey];

        if (optionValue !== true) {
            updatedCookie += '=' + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

export function deleteCookie(name: string): void {
    setCookie(name, '', {
        'max-age': -1,
    });
}
