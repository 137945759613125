import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';

import Metrics from 'components/Metrics';
import NDA from 'components/NDA';
import Providers from 'components/Providers';
import {withSaga} from 'hocs/withSaga';
import {asyncAppPreloader} from 'utils/appPreloader';
import {GlobalSpinner} from 'utils/globalSpinner';

import Content from './Content';
import appSaga from './saga';
import {Props} from './types';

import './styles.css';

const titlePrefix = 'WFM Effrat';

const App: Props = ({className}) => {
    const [isReadyToRender, setReadyToRender] = useState(false);

    useEffect(() => {
        /*
        * Пока не будет сделано SSR
        * В данном прелоадере хранятся только критически важные моменты, которые нужно ещё до старта саг и всего
        * Например, загрузка переводов
        * https://st.yandex-team.ru/TEWFM-386
        * */
        asyncAppPreloader()
            .then(() => {
                setReadyToRender(true);
            });
    }, []);

    if (!isReadyToRender) {
        return null;
    }

    return (
        <div className={className}>
            <Helmet
                titleTemplate={`${titlePrefix}: %s`}
                defaultTitle={titlePrefix}
            />

            <Providers>
                <Content/>

                <Metrics/>
                <GlobalSpinner/>
                <NDA/>
            </Providers>
        </div>
    );
};

App.displayName = 'App';

export default withSaga(appSaga, {isRoot: true})(App as React.ComponentType);
