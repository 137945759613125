import {useStoreMap} from 'effector-react';

import {$configs} from 'shared/models/app-data/app-data';
import {UserConfigs} from 'types/experiments';

export function useUserConfig<T extends keyof UserConfigs>(key: T): UserConfigs[T] {
    return useStoreMap({
        store: $configs,
        fn: configs => configs?.[key],
        keys: [key],
    });
}
