import React from 'react';
import {Route} from 'react-router-dom';

import withSuspense from 'hocs/withSuspense';
import {PATHS} from 'routes/consts';

const ShiftsPlanning = withSuspense(
    React.lazy(() => import('./ShiftsPlanning')),
);

export default (
    <React.Fragment>
        <Route path={PATHS.SHIFTS.PLANNING} component={ShiftsPlanning}/>
    </React.Fragment>
);
