import {stringify} from 'qs';

import {EntityAPI} from 'types/http';
import {nodeApi} from 'utils/httpApi';

import {Request, Response} from './types';

class TranslationsAPI implements EntityAPI {
    public request = (data: Request) => {
        const query = !data ? '' : `?${stringify(data)}`;

        return nodeApi.get<Response>(`/localization${query}`)
            .then(({data: {translations}}) => translations);
    };
}

export const apiInstance = new TranslationsAPI();
