import {PATHS} from 'routes/consts';
import {Modes} from 'types/common';

export const CONTAINER_ID = 'schedules';

export const SECTION_PATHS = {
    INDEX: `${PATHS.SCHEDULES}/:scheduleId?`,
    CREATE: `${PATHS.SCHEDULES}/create`,
    COPY: `${PATHS.SCHEDULES}/:scheduleId/copy`,
};

export const CREATE_MODES_FLOW: Modes[] = [Modes.Create, Modes.Copy];

export enum SchedulesLSKeys {
    ShowArchiveSchedule = 'schedules.showArchiveSchedule'
}
