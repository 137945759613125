import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {EventType} from 'components/Modals/CreateCalendarEvent/components/ModalForm/EventTypeSelect/types';

import {StateType} from './types';

export const defaultState: StateType = EventType.Absence;

export const actions = {
    set: createAction('COMMON/CREATE_CALENDAR_INFO_MODAL/EVENTS_FORM/FORM_TYPE/SET')<StateType>(),
    reset: createAction('COMMON/CREATE_CALENDAR_INFO_MODAL/EVENTS_FORM/FORM_TYPE/RESET')<void>(),
};

const reducer = createReducer<StateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.set, (state: StateType, {payload}) => payload)
    .handleAction(actions.reset, () => EventType.Absence);

export default reducer;
