import {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {bound as actions} from 'reducers/common/localStorage/actions';
import store from 'reduxStore';
import {makeLocalStorageSelector} from 'selectors/common/localStorage';

import {Key} from './types';

export const useLocalStorage = <T>(keyName: Key, init?: T): [T, (newValue: T) => void] => {
    const value = useSelector(makeLocalStorageSelector(keyName, init));

    const setNewValue = useCallback((newValue: T) => {
        actions.set({key: keyName, data: newValue});
    }, [keyName]);

    return [value, setNewValue];
};

export function makeLocalStorageControls<T>(keyName: Key, init?: T): [T, (newValue: T) => void] {
    const value = makeLocalStorageSelector(keyName, init)(store.getState());

    const setNewValue = (newValue: T) => {
        actions.set({key: keyName, data: newValue});
    };

    return [value, setNewValue];
}
