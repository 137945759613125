import {Typography} from 'antd';
import React, {memo} from 'react';
import {Link} from 'react-router-dom';

import {LogoIcon} from 'components/Icons/LogoIcon';

import {LogoItem, SiteTitle, Wrapper} from './styles';
import {Props} from './types';

const Logo: Props = ({className, isCollapsed}) => {
    return (
        <Link to="/">
            <Wrapper
                align="center"
                className={className}
                isCollapsed={isCollapsed}
            >
                <LogoItem>
                    <LogoIcon/>
                </LogoItem>
                <SiteTitle>
                    <Typography.Text>
                        WFM Effrat
                    </Typography.Text>
                </SiteTitle>
            </Wrapper>
        </Link>
    );
};

export default memo<Props>(Logo);
