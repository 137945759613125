import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {FieldsToChangeStateType} from './types';

export const defaultState: FieldsToChangeStateType = {
    data: [],
};

export const actions = {
    set: createAction('COMMON/SHIFT_SIDEBAR/FORMS/FIELDS_TO_CHANGE/SET')<FieldsToChangeStateType['data']>(),
    push: createAction('COMMON/SHIFT_SIDEBAR/FORMS/FIELDS_TO_CHANGE/PUSH')<FieldsToChangeStateType['data']>(),
};

const reducer = createReducer<FieldsToChangeStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.set, (state: FieldsToChangeStateType, action: {payload: FieldsToChangeStateType['data']}) => ({
        data: action.payload,
    }))
    .handleAction(actions.push, (state: FieldsToChangeStateType, action: {payload: FieldsToChangeStateType['data']}) => ({
        data: [...state.data, ...action.payload],
    }));

export default reducer;
