import {EnvType, EnvTypes} from 'reducers/app/types';

export const OPTIONS: Indexed<unknown> = {
    webvisor: true,
};

export const VERSION: '1' | '2' = '2';

const PRODUCTION_ACCOUNTS: number[] = [83976466];
const TESTING_ACCOUNTS: number[] = [93080252];

export const getAccounts = (env: EnvType) => {
    if (env !== EnvTypes.prod) {
        return TESTING_ACCOUNTS;
    }

    return PRODUCTION_ACCOUNTS;
};
