import React from 'react';
import {Redirect, Route} from 'react-router-dom';

import withSuspense from 'hocs/withSuspense';
import {PATHS} from 'routes/consts';

const Reports = withSuspense(
    React.lazy(() => import('./Main/components/Page')),
);

const OperatorStatistic = withSuspense(
    React.lazy(() => import('./OperatorStatistic/components/Page')),
);

export default (
    <React.Fragment>
        <Route
            exact
            path={PATHS.REPORTS.MAIN}
            render={() => <Redirect to={PATHS.REPORTS.CHOICE}/>}
        />

        <Route path={PATHS.REPORTS.CHOICE} component={Reports}/>
        <Route path={PATHS.REPORTS.OPERATOR_STATISTIC} component={OperatorStatistic}/>
    </React.Fragment>
);
