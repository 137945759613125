export const UseQueryStatuses = {
    Idle: 'idle',
    Error: 'error',
    Loading: 'loading',
    Success: 'success',
} as const;

export type UseQueryStatusesType = (typeof UseQueryStatuses)[keyof typeof UseQueryStatuses];

export const enum QueryKeys {
    EmployeeViolations = 'employee.violations',
    SkillsPresets = 'configs.skillsPresets',
    EmployeeCurrentSchedule = 'employee.current_schedule',
    Preprofiles = 'container.nanimator_candidates.preprofiles',

    AbsenceTypes = 'resources.absenceTypes',
    BreakRules = 'resources.breakRules',
    Skills = 'resources.skills',
    Schedules = 'resources.schedules',
    Tags = 'resources.tags',
    ProjectActivities = 'resources.project_activities',
    AllowedPeriod = 'resources.allowed_period',
    Violations = 'resources.violations',
    Breaks = 'resources.breaks',
    Departments = 'resources.departments',
    Employees = 'resources.employees',
    ForecastBrands = 'resources.forecast_brands',
    ForecastMappingValues = 'resources.forecast_mapping_values',
    ForecastMapping = 'resources.forecast_mapping',
    Nanimator = 'nanimator',
    Operator = 'operator',
    Operators = 'operators',
    OperatorExtraInfo = 'operator-extra-info',
    RobotInfo = 'config.robot_info',
    ForecastTemplates = 'forecast_templates',
    Languages = 'config.languages',
    OperatorTimetable = 'resources.operator_timetable',
    IndividualBreaks = 'resources.individual_breaks',
    SchedulePreview = 'resources.schedule_preview',
}
