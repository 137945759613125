import qs from 'qs';

import {QS_PARSE_OPTIONS_DEFAULT} from 'utils/qs/consts';
import {isArray} from 'utils/typescript/typeguards';
import {createTypeSafeString} from 'utils/typescript/typesafe';

const banRegex = /\[.+\]/gm;

export function searchParamsCompat(search: string, urlSearchParams?: URLSearchParams) {
    const parsedQuery = qs.parse(search ?? '', QS_PARSE_OPTIONS_DEFAULT);

    const _urlSearchParams = urlSearchParams ?? new URLSearchParams(search);
    const newUrlSearchParams = new URLSearchParams(_urlSearchParams);

    Object.keys(parsedQuery).forEach(key => {
        const parsedValue = parsedQuery[key];

        if (isArray(parsedValue)) {
            const params = newUrlSearchParams.getAll(key);

            parsedValue.forEach(value => {
                const typeSafeString = createTypeSafeString(value);

                if (typeSafeString && !params.includes(typeSafeString)) {
                    newUrlSearchParams.append(key, typeSafeString);
                }
            });
        } else {
            const param = newUrlSearchParams.get(key);
            const typeSafeString = createTypeSafeString(parsedValue);

            if (typeSafeString && param !== typeSafeString) {
                newUrlSearchParams.set(key, typeSafeString);
            }
        }
    });

    for (const key of newUrlSearchParams.keys()) {
        if (banRegex.test(key)) {
            newUrlSearchParams.delete(key);
        }
    }

    return newUrlSearchParams;
}
