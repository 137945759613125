import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {ChartSelectionInfoStateType} from './types';

export const defaultState: ChartSelectionInfoStateType = {
    uids: [],
};

export const actions = {
    set: createAction('COMMON/CREATE_CALENDAR_INFO_MODAL/CHART_SELECTION/SET')<ChartSelectionInfoStateType>(),
    reset: createAction('COMMON/CREATE_CALENDAR_INFO_MODAL/CHART_SELECTION/RESET')<void>(),
};

const reducer = createReducer<ChartSelectionInfoStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.set, (state: ChartSelectionInfoStateType, {payload}) => payload)
    .handleAction(actions.reset, () => defaultState);

export default reducer;
