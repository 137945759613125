import {combineReducers} from 'redux';

import {LanguageInfo} from 'types/languages';
import {
    generateEntityReducer,
    generateSelectors,
} from 'utils/reducers/flow';

import {StateType} from './types';

const PREFIX = 'COMMON/LANGUAGES';

const {items} = generateEntityReducer<LanguageInfo>(PREFIX);

export const selectors = generateSelectors<GlobalStateType, LanguageInfo>(s => s.common.languages);
export const actions = items.actions;

export default combineReducers<StateType>({
    items: items.reducer,
});
