import {Select} from 'antd';
import React, {memo} from 'react';

import i18n from 'utils/localization';
import {useTimezonesOptions} from 'utils/queries/useTimezones';
import {FULL_WIDTH_STYLE} from 'utils/styles';

import {Props} from './types';

const TimezonesPicker: Props = props => {
    const allowedTimezones = useTimezonesOptions();

    return (
        <Select
            style={FULL_WIDTH_STYLE}
            placeholder={i18n.print('common.timezone')}
            {...props}
            options={allowedTimezones}
        />
    );
};

export default memo(TimezonesPicker);
