import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {Operator} from 'types/api/backend-py3/definitions';

import {CommonEmployeeInfoStateType} from './types';

export const defaultState: CommonEmployeeInfoStateType = {
    data: undefined,
    status: RequestStatusInitial,
    desc: null,
};

export const actions = {
    request: createAction('COMMON_EMPLOYEES/CARD/INFO/REQUEST')<{withDelay: boolean}>(),
    success: createAction('COMMON_EMPLOYEES/CARD/INFO/SUCCESS')<Operator>(),
    fail: createAction('COMMON_EMPLOYEES/CARD/INFO/FAIL')<string>(),

    needUpdateInTable: createAction('COMMON_EMPLOYEES/CARD/INFO/NEED_UPDATE_IN_TABLE')<string[]>(),

    requestUpdateField: createAction('COMMON_EMPLOYEES/CARD/INFO/REQUEST_UPDATE_FIELD')(),
    successUpdateField: createAction('COMMON_EMPLOYEES/CARD/INFO/SUCCESS_UPDATE_FIELD')<Partial<Operator>>(),
    failUpdateField: createAction('COMMON_EMPLOYEES/CARD/INFO/FAIL_UPDATE_FIELD')<string>(),
};

const reducer = createReducer<CommonEmployeeInfoStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.request, () => ({
        data: undefined,
        status: RequestStatusPending,
        desc: null,
    }))
    .handleAction(actions.success, (state: CommonEmployeeInfoStateType, action: {payload: Operator}) => ({
        status: RequestStatusSuccessful,
        desc: null,
        data: action.payload,
    }))
    .handleAction(actions.fail, (state: CommonEmployeeInfoStateType, action: {payload: string}) => ({
        data: undefined,
        status: RequestStatusFailed,
        desc: action.payload,
    }))
    .handleAction(actions.successUpdateField, (state, {payload}) => ({
        ...state,
        data: {
            ...(state.data || {}),
            ...payload,
        } as Operator,
    }));

export default reducer;
