import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {
    BASE_PASSPORT_URL_PROD,
    BASE_PASSPORT_URL_TEST,
    BASE_PASSPORT_URL_YA_TEAM,
    YANDEX_TEAM_DOMAIN,
} from 'components/Menu/Content/Footer/Account/consts';
import {EnvTypes} from 'reducers/app/types';
import envSelector from 'selectors/app/configuration/envSelector';

export const usePassportHost = (): string => {
    const env = useSelector(envSelector);
    const {hostname} = window.location;

    return useMemo(() => {
        const separatedHostname = hostname.split('.');

        if (separatedHostname[separatedHostname.length - 2] === YANDEX_TEAM_DOMAIN) {
            return BASE_PASSPORT_URL_YA_TEAM;
        }
        const isProd = env === EnvTypes.prod;

        return isProd ? BASE_PASSPORT_URL_PROD : BASE_PASSPORT_URL_TEST;
    }, [hostname, env]);
};
