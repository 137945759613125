import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {EditShiftsFormType} from 'types/shifts';

import {StateType} from './types';

export const defaultState: StateType = null;

export const actions = {
    // index of draft
    delete: createAction('COMMON/SHIFT_SIDEBAR/MULTI_SKILL/UPDATE_DRAFT/DELETE')<number>(),
    update: createAction('COMMON/SHIFT_SIDEBAR/MULTI_SKILL/UPDATE_DRAFT/UPDATE')(),
    push: createAction('COMMON/SHIFT_SIDEBAR/MULTI_SKILL/UPDATE_DRAFT/PUSH')<Required<EditShiftsFormType>['multi_skill_segments_drafts'][0]>(),
};

const reducer = createReducer<StateType, ActionType<typeof actions>>(defaultState);

export default reducer;
