import {PaginationProps} from 'antd/lib/pagination';
import React from 'react';

import {PaginationType} from 'types/common';
import {DEFAULT_PAGINATION} from 'utils/reducers/flow';

export function ant2BackPagination(data?: AntPaginationType): PaginationType {
    if (!data || data.current < 0 || data.pageSize <= 0) {
        return {offset: 0, limit: DEFAULT_PAGINATION.pageSize};
    }

    const {pageSize: limit, current} = data;

    return {
        limit: limit,
        offset: (current - 1) * limit,
    };
}

type PaginationItemRender = Required<Pick<PaginationProps, 'itemRender'>>;

export const renderPaginationWithDataCY: PaginationItemRender['itemRender'] = (page, type, original) => {
    return <div data-cy="pagination">{original}</div>;
};

export const DEFAULT_VENDOR_PAGINATION: PaginationType = {
    limit: 10,
    offset: 0,
};
