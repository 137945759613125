import {combineReducers} from 'redux';

import createCalendarEventVisible from './createCalendarEventVisible';
import {StateType} from './types';

const modalsReducer = combineReducers<StateType>({
    createCalendarEventVisible,
});

export default modalsReducer;
