import {SCRIPT_ID, CONTAINER_ID, FORM_URLS_BY_DOMAIN} from './consts';

export const isScriptExist = () => {
    return !!document.getElementById('yndxbug');
};

export const createScript = () => {
    const script = document.createElement('script');

    const separatedHostname = window.location.hostname.split('.');
    const formUrl = FORM_URLS_BY_DOMAIN[separatedHostname[separatedHostname.length - 2]];

    script.async = true;
    script.id = SCRIPT_ID;
    script.src = 'https://yastatic.net/s3/frontend/butterfly/latest/butterfly.js';
    script.setAttribute('container-id', CONTAINER_ID);
    script.setAttribute('data-form-url', formUrl);
    script.setAttribute('modules', 'forms,screenshot');
    script.setAttribute('position', 'left');
    script.setAttribute('screenshot', 'true');

    document.body.appendChild(script);
};
