import moment, {Moment} from 'moment-timezone';

import {TIME_HHMM} from './format';

interface FindMaxDateArgs {
    range?: [Moment, Moment];
    // default value -> moment()
    targetDate?: Moment;
    /*
    * when it's true -> ([0, 4], -1 -> 0)
    * default: false
    * */
    firstIsTarget?: boolean;
}

/*
* it finds minimum next date from target date
*
* examples:
* ([0, 4], 6 -> 6)
* ([0, 4], 4 -> 4)
* ([0, 4], 0 -> 0)
* ([0, 4], 2 -> 2)
* ([0, 4], -1 -> firstIsTarget ? 0 : -1)
* */
export function findMinMaxRangeDate({
    range,
    targetDate = moment(),
    firstIsTarget = true,
}: FindMaxDateArgs): Moment {
    if (!range) {
        return targetDate;
    }

    const [startDate, endDate] = range;

    // special custom conditions to easy reading
    if (targetDate.isAfter(endDate)) {
        return targetDate;
    } else if (targetDate?.isBefore(startDate)) {
        return firstIsTarget ? targetDate : startDate;
    } else if (targetDate.isSame(startDate) || targetDate.isSame(endDate)) {
        return targetDate;
    }

    return targetDate;
}

interface CheckDateInsideRangeOptions {
    // default is true
    isLeftIncludes?: boolean;
    // default is true
    isRightIncludes?: boolean;
}

export function checkDateInsideRange(
    target: Moment,
    range: [Moment, Moment],
    options?: CheckDateInsideRangeOptions,
): boolean {
    const {
        isLeftIncludes = true,
        isRightIncludes = true,
    } = options || {};

    const [lhs, rhs] = range;

    if (isLeftIncludes && lhs.isSame(target)) {
        return true;
    }

    if (isRightIncludes && rhs.isSame(target)) {
        return true;
    }

    if (target.isAfter(lhs) && target.isBefore(rhs)) {
        return true;
    }

    return false;
}

// get array of hours from 00:00 to 23:00
export const getHoursOfDay = () => {
    const date = moment().startOf('day');
    const nextDayDate = date.clone().add(1, 'days');
    const result: string[] = [];

    while (date.isBefore(nextDayDate)) {
        result.push(date.format(TIME_HHMM));
        date.add(1, 'hours');
    }

    return result;
};

export const mergeDateTime = (date: Moment, time: Moment) => {
    let dateTime = date.clone().startOf('day');

    dateTime = dateTime.add(time.hour(), 'hour');
    dateTime = dateTime.add(time.minute(), 'minute');

    return dateTime;
};

type MakeFormattedPeriodOptions = {
    from?: string;
    to?: string;
    format: string;
    withoutSameEnd?: boolean;
};

export const makeFormattedPeriod = ({
    from,
    to,
    format,
    withoutSameEnd,
}: MakeFormattedPeriodOptions) => {
    const start = from ? moment(from).format(format) : '∞';
    const end = to ? moment(to).format(format) : '∞';

    if (withoutSameEnd && start === end) {
        return start;
    }

    return `${start} - ${end}`;
};

// sort and remove intersections;
export const mergePeriods = (periods: {from: Moment; to: Moment}[]) => {
    if (periods.length === 0) {
        return [];
    }

    const sortedPeriods = periods.sort((a, b) => a.from.isBefore(b.from) ? -1 : 1);
    const result = [sortedPeriods[0]];

    for (let i = 1; i < sortedPeriods.length; i++) {
        const prev = result[result.length - 1];
        const current = sortedPeriods[i];

        if (current.from.isAfter(prev.to)) {
            result.push(current);
        } else {
            result[result.length - 1] = {
                from: prev.from,
                to: prev.to.isAfter(current.to) ? prev.to : current.to,
            };
        }
    }

    return result;
};

export const convertToLocalDate = (momentDate?: Nullable<Moment>) => momentDate?.clone().local(true).toDate();
