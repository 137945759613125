import {V1ConfigsValuesRequest, V1ConfigsValuesResponse} from 'types/api/backend-py3/api/v1_configs_values';
import {ConfigName} from 'types/api/backend-py3/definitions/configs';
import {callCenterApi} from 'utils/httpApi';

class ConfigsAPI {
    public request = (configs: ConfigName[]) => {
        const payload: V1ConfigsValuesRequest = {config_names: configs};

        return callCenterApi.post<V1ConfigsValuesResponse>('/v1/configs/values', payload);
    };
}

export const apiInstance = new ConfigsAPI();
