import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {bound as commonActions} from 'reducers/actions';
import {globalSpinnerVisibleSelector} from 'selectors/app/ui';

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .6);
    z-index: 99;
`;
const Indicator = <LoadingOutlined style={{fontSize: 36}} spin/>;

export const GlobalSpinner: FC = () => {
    const isVisible = useSelector(globalSpinnerVisibleSelector);

    if (!isVisible) {
        return null;
    }

    return (
        <Wrapper>
            <Spin indicator={Indicator}/>
        </Wrapper>
    );
};

export default class GlobalSpinnerController {
    public static show() {
        commonActions.app.setGlobalLoader(true);
    }
    public static hide() {
        commonActions.app.setGlobalLoader(false);
    }
}
