import React from 'react';
import styled from 'styled-components';

import {NDA_ICON} from './consts';

const NDA_LINK = 'https://wiki.yandex-team.ru/security/nda';

const NDAIconLink = ({className}: {className: string}) =>
    (
        <a
            className={className}
            href={NDA_LINK}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img alt="NDA" src={NDA_ICON}/>
        </a>
    );

export default styled(NDAIconLink)`
    position: fixed;
    right: -18px;
    top: -18px;
    z-index: 5;

    img {
        width: 60px;
    }
` as React.ComponentType;
