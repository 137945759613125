import {combineReducers} from 'redux';

import {generateEntityReducer} from 'utils/reducers/flow';

import {SpreadInfoType, StateType} from './types';

const PREFIX = 'COMMON/CREATE_CALENDAR_INFO_MODAL/EVENTS_FORM/REQUEST';

const {item} = generateEntityReducer<SpreadInfoType>(PREFIX);

export const actions = item!.actions;

export default combineReducers<StateType>({
    item: item!.reducer,
});
