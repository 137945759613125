import CloseIcon from '@ant-design/icons/CloseCircleOutlined';
import {Typography} from 'antd';
import React, {useCallback, useMemo} from 'react';

import {useLocalStorage} from 'hooks/useLocalStorage';
import i18n from 'utils/localization';
import {useHeaderAlertSettings} from 'utils/queries/useHeaderAlertSettings';

import {WFM_EXPERIENCE_POLL_KEY} from './consts';
import {AlertHeader} from './styles';
import {Props} from './types';

const {Text, Link} = Typography;

const MainHeaderAlert: Props = ({isCollapsed}) => {
    const {data, isLoading} = useHeaderAlertSettings();
    const [isShowAlert, setIsShowAlert] = useLocalStorage<boolean | undefined>(data?.unique_key || WFM_EXPERIENCE_POLL_KEY, true);
    const handleHiddenAlert = useCallback(() => {
        setIsShowAlert(false);
    }, [setIsShowAlert]);
    const label = useMemo(() => {
        if (!!data?.label_tanker_key) {
            return i18n.print(data.label_tanker_key);
        }

        return data?.default_label || i18n.print('components.page.mainheaderalert.evaluate_your_experience_using_wfm_effrat');
    }, [data?.default_label, data?.label_tanker_key]);
    const linkLabel = useMemo(() => {
        if (!!data?.link_label_tanker_key) {
            return i18n.print(data.link_label_tanker_key);
        }

        return data?.default_link_label || i18n.print('components.page.mainheaderalert.the_survey');
    }, [data?.default_link_label, data?.link_label_tanker_key]);

    if (isLoading || !data?.show || !isShowAlert) {
        return null;
    }

    return (
        <AlertHeader isCollapsed={isCollapsed}>
            <Text>
                {label}&nbsp;
                {!!data?.link && (
                    <Link href={data.link} target="_blank">
                        {linkLabel}
                    </Link>
                )}
            </Text>
            <CloseIcon style={{color: 'red'}} onClick={handleHiddenAlert}/>
        </AlertHeader>
    );
};

export default MainHeaderAlert;
