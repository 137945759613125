import {produce, Draft} from 'immer';
import merge from 'lodash/merge';
import {combineReducers} from 'redux';
import {PayloadAction, createAction} from 'typesafe-actions';

import {OperatorsAbsencesValuesResponse} from 'types/api/backend-py3/api/v1_operators_absences_values';
import {generateEntityReducer, generateSelectors} from 'utils/reducers/flow';

import {AbsenceTypesStateType} from './types';

const PREFIX = 'COMMON_EMPLOYEES/CARD/ABSENCES/LIST';

const {item} = generateEntityReducer<OperatorsAbsencesValuesResponse>(PREFIX);

export const selectors = generateSelectors<GlobalStateType, OperatorsAbsencesValuesResponse>(
    s => s.common.employees.card.absences.list,
);
export const actions = {
    ...item!.actions,
    append: createAction(`${PREFIX}/APPEND`)<OperatorsAbsencesValuesResponse>(),
};

const reducer = item!.reducer
    .handleAction(
        actions.append,
        (state: GlobalStateType, {payload}: PayloadAction<string, OperatorsAbsencesValuesResponse>) => {
            return produce(state, (draft: Draft<AbsenceTypesStateType['item']>) => {
                if (!draft) {
                    return {
                        data: payload,
                        status: RequestStatusSuccessful,
                        desc: null,
                    } as Draft<AbsenceTypesStateType['item']>;
                }

                draft.data = merge(draft.data, payload as typeof draft.data);
                draft.status = RequestStatusSuccessful;
            });
        },
    );

export default combineReducers<AbsenceTypesStateType>({
    item: reducer,
});
