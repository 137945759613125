import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {StateType, RequestType, AcceptDraftType} from './types';

export const defaultState: StateType = {
    data: null,
    status: RequestStatusInitial,
    desc: null,
};

export const actions = {
    request: createAction('COMMON/SHIFT_SIDEBAR/DRAFTS/ACCEPT/REQUEST')<RequestType>(),
    success: createAction('COMMON/SHIFT_SIDEBAR/DRAFTS/ACCEPT/SUCCESS')<AcceptDraftType>(),
    fail: createAction('COMMON/SHIFT_SIDEBAR/DRAFTS/ACCEPT/FAIL')<string>(),
    reset: createAction('COMMON/SHIFT_SIDEBAR/DRAFTS/ACCEPT/RESET')(),
};

const reducer = createReducer<StateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.request, () => ({
        status: RequestStatusPending,
        desc: null,
        data: null,
    }))
    .handleAction(actions.success, () => ({
        status: RequestStatusSuccessful,
        desc: null,
        data: null,
    }))
    .handleAction(actions.fail, (state, action) => ({
        status: RequestStatusFailed,
        desc: action.payload,
        data: null,
    }))
    .handleAction(actions.reset, () => ({...defaultState}));

export default reducer;
