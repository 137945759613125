import {DEFAULT_STATE} from '../consts';
import {BaseEntityState, RequestParams, StatePathSelectorFn} from '../types';

import {createEntityActions} from './createEntityActions';
import {createEntityReducer} from './createEntityReducer';
import {createEntitySelectors} from './createEntitySelectors';
import {CreateEntityResult, CreateEntityWithSelectorsResult, Params} from './types';

export function createEntity<TData, TRequest extends RequestParams = void>(
    params: Params<TData>,
): CreateEntityResult<TData, TRequest>;
export function createEntity<TData, TRequest extends RequestParams = void>(
    params: Params<TData>,
    stateSelectorPath?: StatePathSelectorFn<BaseEntityState<TData>>,
): CreateEntityWithSelectorsResult<TData, TRequest>;
export function createEntity<TData, TRequest extends RequestParams = void>(
    params: Params<TData>,
    stateSelectorPath?: StatePathSelectorFn<BaseEntityState<TData>>,
): CreateEntityResult<TData, TRequest> | CreateEntityWithSelectorsResult<TData, TRequest> {
    const _defaultState: BaseEntityState<TData> = {
        data: null,
        ...DEFAULT_STATE,
        ...(params.defaultState || {}),
    };

    const actions = createEntityActions<TData, TRequest>(params.prefix);

    const reducer = createEntityReducer<TData, TRequest>(
        params.prefix,
        actions,
        {
            defaultState: _defaultState,
            preventDataCleanupOnRequest: false,
        },
    )();

    if (stateSelectorPath) {
        const selectors = createEntitySelectors<TData>(stateSelectorPath);

        return {reducer, actions, selectors};
    }

    return {
        reducer,
        actions,
    };
}
