import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {Modes} from 'types/common';

import {StateType} from './types';

export const defaultState: StateType = null;

export const actions = {
    set: createAction('COMMON/SHIFT_SIDEBAR/SKILL/SET')<Modes>(),
    reset: createAction('COMMON/SHIFT_SIDEBAR/SKILL/RESET')<void>(),
};

const reducer = createReducer<StateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.set, (_state, {payload}) => payload)
    .handleAction(actions.reset, () => null);

export default reducer;
