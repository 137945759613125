import {Space} from 'antd';
import styled from 'styled-components';

import {colors} from 'utils/styles/colors';

export const LogoItem = styled.span`
    font-size: 32px;
    line-height: 32px;
`;

export const SiteTitle = styled.span`
    margin-left: 4px;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all .2s;
`;

export const Wrapper = styled(Space)<{isCollapsed: boolean}>`
    height: 52px;
    width: 100%;
    padding: 10px;
    box-shadow: inset 0 0 0 1px ${colors.dirtyWhite};
    transition: all .2s;


    ${({isCollapsed}) =>
        isCollapsed
            ? `
                padding: 10px 19px;

                ${SiteTitle} {
                    width: 0;
                    opacity: 0;
                }
            `
            : `
                padding: 10px 16px;

                ${SiteTitle} {
                    width: auto;
                    opacity: 1;
                }
            `}
`;
