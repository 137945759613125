import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import reduceReducers from 'reduce-reducers';
import {AnyAction, combineReducers} from 'redux';
import {createAction, createReducer, Reducer} from 'typesafe-actions';

import {routerReducer} from 'router';

import app from './app';
import common from './common';
import resources from './resources';
import {ExtensionType} from './types';

const REDUCERS_HASH: Record<string, Reducer<any, AnyAction>> = {
    app,
    resources,
    common,
    router: routerReducer,
};

export function hasReducer(key: string) {
    return !!(REDUCERS_HASH)[key];
}

export const globalActions = {
    restore: createAction('GLOBAL/RESTORE')(),
    put: createAction('GLOBAL/PUT')(),
};

export default function rootReducerFactory(extension: ExtensionType, shouldRemove = false) {
    let finalHash;

    if (shouldRemove) {
        if (extension) {
            Object
                .keys(extension)
                .forEach(key => {
                    delete REDUCERS_HASH[key];
                });
        }

        finalHash = REDUCERS_HASH;
    } else {
        finalHash = Object.assign(REDUCERS_HASH, extension);
    }

    const combinedReducers = combineReducers(finalHash);

    const patchReducer = createReducer(combinedReducers)
        .handleAction(globalActions.restore, (state: GlobalStateType, action: AnyAction) => merge(cloneDeep(state), action.payload))
        .handleAction(globalActions.put, (state: GlobalStateType, action: AnyAction) => ({
            ...state,
            ...action.payload,
        }));

    return reduceReducers(
        combinedReducers,
        patchReducer,
    );
}
