import {combineReducers} from 'redux';

import modal from './modal';
import {ScheduleStateType} from './types';

const reducer = combineReducers<ScheduleStateType>({
    modal,
});

export default reducer;
