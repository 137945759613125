import {combineReducers} from 'redux';

import {generateEntityReducer} from 'utils/reducers/flow';

import {DetachAbsenceStateType} from './types';

const PREFIX = 'COMMON_EMPLOYEES/CARD/ABSENCES/DETACH';

const {item} = generateEntityReducer<null>(PREFIX);

export const actions = item!.actions;

export default combineReducers<DetachAbsenceStateType>({
    item: item!.reducer,
});
