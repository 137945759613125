import uniq from 'lodash/uniq';
import moment from 'moment-timezone';
import ls from 'store';

import {Timezones, SettingsState} from 'reducers/app/types';

// идея была в том, чтобы обновлять вьюхи при изменении значения state.app.timezone
// на деле оказалось, что пришлось бы вносить значительное число правок во всяких селекторах
// так что пока что реализация сделана через window.location.reload
// будет сделано в https://st.yandex-team.ru/TEF-866
// @note этот класс актуален только до момента, пока обновление настроек сделано через window.location.reload
export default class SettingsApi {
    static TIMEZONE_KEY = 'timezone';
    static DEFAULT_TIMEZONE = Timezones.MSK;
    static SETTINGS_KEY = 'settings';
    static DEFAULT_SETTINGS = {};
    static ALLOWED_TIMEZONES = uniq([
        Timezones.MSK,
        Timezones.LOCAL,
        Timezones.UTC,
    ]);

    static getStoredSettings = () => {
        return ls.get(SettingsApi.SETTINGS_KEY);
    };

    static getSettings = () => {
        return SettingsApi.getStoredSettings() || SettingsApi.DEFAULT_SETTINGS;
    };

    static getStoredTimezone = () => {
        const settings = SettingsApi.getSettings();

        return settings[SettingsApi.TIMEZONE_KEY];
    };

    static getTimezone = () => {
        return SettingsApi.getStoredTimezone() || SettingsApi.DEFAULT_TIMEZONE;
    };

    static initTimezone = () => {
        const timezone = SettingsApi.getTimezone();

        moment.tz.setDefault(timezone);
    };

    static storeSettings = (settings: SettingsState) => {
        ls.set(SettingsApi.SETTINGS_KEY, settings);
    };

    static applyChanges = () => {
        window.location.reload();
    };

    static getAllowedTimezonesOptions = () => {
        return SettingsApi.ALLOWED_TIMEZONES.map(timezone => ({
            value: timezone,
            label: `${timezone} (${moment.tz(timezone).format('Z')})`,
        }));
    };
}
