import {combineReducers} from 'redux';

import {containerReducer as statsReducer} from './stats';
import {actions as statsLoadActions} from './stats/load';
import {actions as statsPaginationActions} from './stats/pagination';

const containerReducer = combineReducers({
    stats: statsReducer,
});

export const actions = {
    stats: {
        load: statsLoadActions,
        pagination: statsPaginationActions,
    },
};

export default containerReducer;
