import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {SaveShiftStateType, SaveShiftResponseType, RequestData} from './types';

export const defaultState: SaveShiftStateType = {
    data: null,
    status: RequestStatusInitial,
    desc: null,
};

export const actions = {
    requestSaveShift: createAction('COMMON/SHIFT_SIDEBAR/FORMS/SHIFT/SAVE/REQUEST')<RequestData>(),
    successSaveShift: createAction('COMMON/SHIFT_SIDEBAR/FORMS/SHIFT/SAVE/SUCCESS')<SaveShiftResponseType>(),
    failSaveShift: createAction('COMMON/SHIFT_SIDEBAR/FORMS/SHIFT/SAVE/FAIL')<string>(),
    reset: createAction('COMMON/SHIFT_SIDEBAR/FORMS/SHIFT/SAVE/RESET')(),
};

const reducer = createReducer<SaveShiftStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.requestSaveShift, state => ({
        ...state,
        status: RequestStatusPending,
        desc: null,
        data: null,
    }))
    .handleAction(actions.successSaveShift, (state, action: {payload: SaveShiftResponseType}) => ({
        ...state,
        status: RequestStatusSuccessful,
        desc: null,
        data: action.payload,
    }))
    .handleAction(actions.failSaveShift, (state: SaveShiftStateType, action: {payload: string}) => ({
        ...state,
        status: RequestStatusFailed,
        desc: action.payload,
        data: null,
    }))
    .handleAction(actions.reset, () => ({...defaultState}));

export default reducer;
