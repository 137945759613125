import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {OperatorsScheduleTypeModifyBulkRequest, OperatorsScheduleTypeModifyBulkResponse} from 'types/api/backend-py3/api/v1_operators_schedule_type_modify';

import {ApplySchedulesToEmployeeStateType} from './types';

export const defaultState: ApplySchedulesToEmployeeStateType = {
    data: undefined,
    status: RequestStatusInitial,
    desc: null,
};

export const actions = {
    requestApplySchedulesToEmployee: createAction('COMMON_EMPLOYEES/CARD/SCHEDULES/APPLY/REQUEST')<OperatorsScheduleTypeModifyBulkRequest>(),
    successApplySchedulesToEmployee: createAction('COMMON_EMPLOYEES/CARD/SCHEDULES/APPLY/SUCCESS')<OperatorsScheduleTypeModifyBulkResponse>(),
    failApplySchedulesToEmployee: createAction('COMMON_EMPLOYEES/CARD/SCHEDULES/APPLY/FAIL')<string>(),
};

const reducer = createReducer<ApplySchedulesToEmployeeStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.requestApplySchedulesToEmployee, state => ({
        ...state,
        status: RequestStatusPending,
        desc: null,
        data: undefined,
    }))
    .handleAction(actions.successApplySchedulesToEmployee, (state, action: {payload: OperatorsScheduleTypeModifyBulkResponse}) => ({
        ...state,
        status: RequestStatusSuccessful,
        desc: null,
        data: action.payload,
    }))
    .handleAction(actions.failApplySchedulesToEmployee, (state: ApplySchedulesToEmployeeStateType, action: {payload: string}) => ({
        ...state,
        status: RequestStatusFailed,
        desc: action.payload,
        data: undefined,
    }));

export default reducer;
