import {combineReducers} from 'redux';

import {generateEntityReducer} from 'utils/reducers/flow';

import {ApplyTagsEmployeeStateType} from './types';

const PREFIX = 'COMMON_EMPLOYEES/CARD/APPLY_TAGS';

const {item} = generateEntityReducer<void>(PREFIX);

export const actions = item!.actions;

export default combineReducers<ApplyTagsEmployeeStateType>({
    item: item!.reducer,
});
