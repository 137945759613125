import {combineReducers} from 'redux';

import {generateEntityReducer} from 'utils/reducers/flow';

import {ModifyEmployeesResponseType, ModifyEmployeeBulkStateType} from './types';

const PREFIX = 'COMMON_EMPLOYEES/CARD/TABLE_BULK/MODIFY_BULK';

const {item} = generateEntityReducer<ModifyEmployeesResponseType>(PREFIX);

export const actions = item!.actions;

export default combineReducers<ModifyEmployeeBulkStateType>({
    item: item!.reducer,
});
