import {
    SettingOutlined,
    BugOutlined,
    QuestionCircleOutlined,
    TrademarkCircleOutlined,
} from '@ant-design/icons';
import React, {MemoExoticComponent} from 'react';

import AboutServiceWrapper from './AboutServiceWrapper';
import BugReport from './BugReportWrapper';
import DocumentationWrapper from './DocumentationWrapper';
import SettingsWrapper from './SettingsWrapper';
import {ItemPropsType} from './types';

const ICON_STYLE: React.CSSProperties = {fontSize: 16};

export const MENU_FOOTER_ICONS_MAP: Record<string, JSX.Element> = {
    SettingOutlined: <SettingOutlined style={ICON_STYLE}/>,
    QuestionCircleOutlined: <QuestionCircleOutlined style={ICON_STYLE}/>,
    BugOutlined: <BugOutlined style={ICON_STYLE}/>,
    TrademarkCircleOutlined: <TrademarkCircleOutlined style={ICON_STYLE}/>,
} as const;

export const MENU_FOOTER_WRAPPERS_MAP: Record<string, MemoExoticComponent<ItemPropsType>> = {
    SettingsWrapper: SettingsWrapper,
    DocumentationWrapper: DocumentationWrapper,
    AboutServiceWrapper: AboutServiceWrapper,
    FeedbackWrapper: BugReport,
} as const;
