import {Typography} from 'antd';
import styled from 'styled-components';

export const DocumentationWrapper = styled(Typography.Paragraph)`
    width: 100%;
    padding-top: 12px;

    ul li {
        display: block;
        height: 28px;
        align-content: center;
        margin: 0;
        padding: 0 0 0 8px;
    }
`;
