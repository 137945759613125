import {TranslationsAPI} from 'api/localization';
import {bound as actions} from 'reducers/common/localization/actions';
import {LoadOptions, TJSonTextTranslation} from 'utils/localization/types';

import {KEYSETS, DEFAULT_LANGUAGE} from './consts';
import TJSON from './tjson';

class I18N {
    protected static instance: I18N = new I18N();
    protected tjson: Nullable<TJSON> = null;

    constructor() {
        if (I18N.instance) {
            throw new Error('i18n error: use I18N.getInstance() instead of new');
        }
    }

    public static getInstance(): I18N {
        return I18N.instance;
    }

    public import(translations: TJSonTextTranslation, language: string) {
        this.tjson = null;
        this.tjson = I18N.createTJSON(translations, language);
    }

    public print(key: Parameters<TJSON['print']>[0], options?: Parameters<TJSON['print']>[1]) {
        if (!this.tjson) {
            return key;
        }

        const {keyset = KEYSETS.ORIGIN} = options || {};

        return this.tjson.keyset(keyset).print(key, {
            ...options,
            keyset,
        });
    }

    /*
    * Загружает кейсеты для языка
    * */
    public async load(language: string, loadOptions?: LoadOptions) {
        const {withStore = true} = loadOptions || {};

        if (withStore) {
            actions.reset();
        }
        this.tjson = null;

        const translations = await TranslationsAPI.request({
            lang: `${language},${DEFAULT_LANGUAGE}`,
            keysets: Object.values(KEYSETS).join(','),
        });

        const preparedData = I18N.createTJSON(translations, language);

        if (preparedData) {
            this.tjson = preparedData;

            if (withStore) {
                actions.ready();
            }

            return preparedData;
        } else {
            console.error('i18n error: tjson.load ended with error');
        }
    }

    private static createTJSON(data: TJSonTextTranslation, language: string) {
        return new TJSON(data).lang(language);
    }
}

const i18n = I18N.getInstance();

export {DEFAULT_LANGUAGE} from './consts';
export default i18n;
