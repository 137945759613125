import flowRight from 'lodash/flowRight';

import {last} from 'utils/lodash';

const DEFAULT_PROXY_OPTIONS = {
    has() {
        throw new Error('The path function must not contain an argument call via the in operator');
    },
};

function collect(arr: string[][]) {
    return () => {
        return new Proxy(
            Object.create(null),
            {
                get(_: unknown, prop: string) {
                    const currentArr: string[] = [];

                    arr.push(currentArr);
                    currentArr.push(prop);
                    const childProxy: ProxyHandler<object> = new Proxy(
                        Object.create(null),
                        {
                            get(_: unknown, childProp: string) {
                                currentArr.push(childProp);
                                return childProxy;
                            },
                            ...DEFAULT_PROXY_OPTIONS,
                        });

                    return childProxy;
                },
                ...DEFAULT_PROXY_OPTIONS,
            });
    };
}

function pathToString(arr: string[]) {
    return arr.reduce((result, item) => {
        const prefix = result === '' ? '' : '.';

        return result + (isNaN(Number(item)) ? prefix + item : `[${item}]`);
    }, '');
}

export default function pathFromFunction<T, R>(path: (object: T) => R): string {
    const arr: string[][] = [[]];
    const resultProxy = flowRight([path, collect(arr)])();

    if (!resultProxy) {
        throw new Error('There is no return in the path function');
    }
    if (arr.length > 2) {
        throw new Error(
            `The path function contains unnecessary references to the properties of the argument
            or is the argument itself used in the calculations of the argument property`,
        );
    }
    return pathToString(last(arr) || []);
}
