import {combineReducers} from 'redux';

import load, {actions as loadActions} from './load';
import periodFilter from './periodFilter';
import shifts, {actions as shiftsActions} from './shifts';
import ui, {actions as chartUIActions} from './ui';

const employeeChartReducer = combineReducers({
    ui,
    periodFilter,
    shifts,
    load,
});

export const actions = {
    ui: chartUIActions,
    shifts: shiftsActions,
    load: loadActions,
};

export default employeeChartReducer;
