import {combineReducers} from 'redux';

import drafts from './drafts';
import forms from './forms';
import modals from './modals';
import mode from './mode';
import multiSkill from './multiSkill';
import prepareCreateShiftOperatorDatePeriod from './prepareCreateShiftOperatorDatePeriod';
import removeViolations from './removeViolations';
import saveViolations from './saveViolations';
import shift from './shift';
import shiftData from './shiftData';
import shiftsFilters from './shiftsFilters';
import shiftsList from './shiftsList';
import skill from './skill';
import {ShiftSidebarStateType} from './types';

const shiftSidebarReducer = combineReducers<ShiftSidebarStateType>({
    forms,
    drafts,
    shiftsFilters,
    shiftData,
    shiftsList,
    shift,
    saveViolations,
    removeViolations,
    mode,
    modals,
    skill,
    multiSkill,
    prepareCreateShiftOperatorDatePeriod,
});

export {shiftSidebarReducer};
