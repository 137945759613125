import React, {memo, useMemo} from 'react';
import {useSelector} from 'react-redux';

import CroppedText from 'components/Text/CroppedText';
import {domainsOptionsSelector} from 'selectors/resources/domains/domains';
import {getDomain} from 'utils/domain';

import {Props} from './types';

const domain = getDomain();
const preparedDomain = domain.charAt(0).toUpperCase() + domain.slice(1);
const bigMaxLength = 100;

const CurrentDomain: Props = ({maxLength}) => {
    const domains = useSelector(domainsOptionsSelector);
    const domainLabel = useMemo(
        () => domains.find(({value}) => value === domain)?.label,
        [domains],
    );

    return (
        <CroppedText
            maxLength={maxLength || bigMaxLength}
            text={domainLabel || preparedDomain}
        />
    );
};

export default memo(CurrentDomain);
