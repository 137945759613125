import {useQuery} from '@tanstack/react-query';
import {notification} from 'antd';

import {ConfigsAPI} from 'api/configs';
import {WFMFrontendUiLanguages} from 'types/api/backend-py3/definitions/configs';
import {getErrorMessage} from 'utils/axios';
import i18n from 'utils/localization';
import {QueryKeys} from 'utils/queries/consts';

export const useLanguagesQuery = () => {
    return useQuery({
        queryKey: [QueryKeys.Languages],
        queryFn: async () => {
            try {
                const response = await ConfigsAPI.request(['WFM_FRONTEND_UI_LANGUAGES']);

                if (!response.data.configs) {
                    return undefined;
                }

                const config = response.data.configs[0] as WFMFrontendUiLanguages;

                return config.body;
            } catch (exception) {
                const {message} = getErrorMessage(exception);

                notification.error({
                    message: i18n.print('common.error'),
                    description: message,
                });

                return undefined;
            }
        },
        gcTime: Infinity,
    });
};
