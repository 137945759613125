import {isArray, isNumber, isString} from 'utils/typescript/typeguards';

type GuardFunction<T = unknown> = (value: unknown) => value is T;

export function createTypeSafeArray<T>(value: unknown | unknown[], guard: GuardFunction<T>): T[] {
    if (!isArray(value)) {
        if (guard(value)) {
            return [value] as T[];
        }

        return [] as T[];
    }

    return value.reduce<T[]>((acc, element) => {
        if (guard(element)) {
            acc.push(element);
        }

        return acc;
    }, []);
}

export function createTypeSafeNumber(value: unknown): Undefinable<number>;
export function createTypeSafeNumber(value: unknown, defaultValue: number): number;
export function createTypeSafeNumber(value: unknown, defaultValue?: number): Undefinable<number> {
    if (isNumber(value)) {
        return value;
    }

    if (isString(value)) {
        const result = Number(value);

        if (!isNaN(result)) {
            return result;
        }
    }

    if (defaultValue !== undefined) {
        return defaultValue;
    }

    return undefined;
}

export function createTypeSafeString(value: unknown): Undefinable<string>;
export function createTypeSafeString(value: unknown, defaultValue: string): string;
export function createTypeSafeString(value: unknown, defaultValue?: string): Undefinable<string> {
    if (isString(value)) {
        return value;
    }

    if (isNumber(value)) {
        return value.toString();
    }

    if (defaultValue !== undefined) {
        return defaultValue;
    }

    return undefined;
}
