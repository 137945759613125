import {Row, Col, Select} from 'antd';
import React, {FC, memo, useMemo} from 'react';

import {cookies, CookieName} from 'utils/cookies';
import i18n from 'utils/localization';
import {useLanguagesQuery} from 'utils/queries/languages/useLanguagesQuery';
import {FULL_WIDTH_STYLE, ROW_GUTTER} from 'utils/styles';

import {NATIVE_LANGUAGE_NAME_MAP} from './consts';

function handleChangeLanguage(language: string) {
    cookies.set(CookieName.Language, language);
    window.location.reload();
}

const language = cookies.get(CookieName.Language);

const LanguageSelect: FC = () => {
    const {data: languages} = useLanguagesQuery();

    const options = useMemo(() => {
        return (languages || []).reduce<OptionType[]>((acc, {lang, isActive}) => {
            if (isActive) {
                acc.push({
                    value: lang,
                    label: `${i18n.print(`common.languages.${lang}`)}${NATIVE_LANGUAGE_NAME_MAP[lang] ? ` (${NATIVE_LANGUAGE_NAME_MAP[lang]})` : ''}`,
                });
            }

            return acc;
        }, []);
    }, [languages]);

    return (
        <Row gutter={ROW_GUTTER} align="middle">
            <Col span={8}>{i18n.print('components.menu.language')}</Col>

            <Col span={16}>
                <Select
                    value={language}
                    options={options}
                    onChange={handleChangeLanguage}
                    style={FULL_WIDTH_STYLE}
                />
            </Col>
        </Row>
    );
};

export default memo(LanguageSelect);
