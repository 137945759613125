import {combineReducers} from 'redux';

import {generateEntityReducer} from 'utils/reducers/flow';

import {ModifyEmployeeExtraInfoResponseType, ModifyEmployeeExtraInfoStateType} from './types';

const PREFIX = 'COMMON_EMPLOYEES/CARD/MODIFY_EXTRA_INFO';

const {item} = generateEntityReducer<ModifyEmployeeExtraInfoResponseType>(PREFIX);

export const actions = item!.actions;

export default combineReducers<ModifyEmployeeExtraInfoStateType>({
    item: item!.reducer,
});
