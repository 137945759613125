import {grey} from '@ant-design/colors';
import {BadgeProps} from 'antd';
import {RowProps} from 'antd/lib/row';
import {CSSProperties} from 'react';

export const NO_MARGIN_STYLE: CSSProperties = {margin: 0};
export const FULL_WIDTH_STYLE: CSSProperties = {width: '100%'};
export const SEMI_FULL_WIDTH_STYLE: CSSProperties = {width: '75%'};
export const HALF_WIDTH_STYLE: CSSProperties = {width: '50%'};
export const SEMI_HALF_WIDTH_STYLE: CSSProperties = {width: '25%'};
export const FULL_HEIGHT_STYLE: CSSProperties = {height: '100%'};
export const ALIGN_RIGHT_STYLE: CSSProperties = {textAlign: 'right'};
export const DISPLAY_NONE_STYLE: CSSProperties = {display: 'none'};
export const NORMAL_TEXT_WEIGHT_STYLE: CSSProperties = {fontWeight: 400};

export const HALF_OPACITY_HEX = 80;

export const ROW_GUTTER: RowProps['gutter'] = [8, 8];

// В антд 4.20+ не работает gutter как margin для строк, когда строка одна либо для крайних строк
export const ROW_MARGIN: CSSProperties = {marginBottom: 8};

export const BADGE_OFFSET: BadgeProps['offset'] = [8, 0];
export const CENTER_CONTENT_STYLE: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

export const FLEX_SPACE_BETWEEN_STYLE: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
};

export const GRAY_COLOR: CSSProperties = {color: grey[0]};
export const GRAY_BORDER_BOTTOM: CSSProperties = {borderBottom: '1px solid #d9d9d9'};
export const BLACK_COLOR: CSSProperties = {color: 'black'};

export const CYAN_BACKGROUND: CSSProperties = {backgroundColor: '#13c2c2'};
