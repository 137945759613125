import {combineReducers} from 'redux';

import remove from './remove';
import save from './save';

const containerReducer = combineReducers({
    save,
    remove,
});

export default containerReducer;
