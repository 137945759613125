import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import React from 'react';
import styled from 'styled-components';

import {dataCY, DataCYPaths} from 'utils/dataCY';

const SpinCenterWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
`;

const DEFAULT_SIZE = 36;

export const cyName = dataCY(DataCYPaths.Components, 'spin-wrapper');

export const CenterLoader = ({size = DEFAULT_SIZE}: {size?: number}) => {
    const style = {fontSize: size};
    const Indicator = <LoadingOutlined style={style} spin/>;

    return (
        <SpinCenterWrapper data-cy={cyName('component')}>
            <Spin indicator={Indicator}/>
        </SpinCenterWrapper>
    );
};
