import {JobSetupResponse} from 'types/api/backend-py3/definitions/job';
import {generateEntityReducer} from 'utils/reducers/flow';

const PREFIX = 'COMMON_EMPLOYEES/CARD/SHIFTS/SETUP/TASK/CREATE';

const {item} = generateEntityReducer<JobSetupResponse>(PREFIX);

export const taskCreateActions = item!.actions;

export default item!.reducer;
