import {combineReducers} from 'redux';

import accept from './accept';
import reject from './reject';
import {ShiftDraftStateType} from './types';

const shiftDraftReducer = combineReducers<ShiftDraftStateType>({
    accept,
    reject,
});

export default shiftDraftReducer;
