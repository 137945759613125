import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {userPermissionsSelector} from 'selectors/app/user';

export const validatePermission = (permissions: string[], required: string | string[]) => {
    if (Array.isArray(required)) {
        return required.every(x => permissions.includes(x));
    }

    return permissions.includes(required);
};

export const usePermissions = (required: string[] | string) => {
    const permissions = useSelector(userPermissionsSelector);

    const allowed = useMemo(() => {
        return validatePermission(permissions, required);
    }, [required, permissions]);

    return {allowed};
};

export {PermissionPresets} from './presets';
