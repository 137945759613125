import {generateEntityReducer} from 'utils/reducers/flow';

import {AttachAbsenceWithCbType} from './types';

const PREFIX = 'COMMON/CREATE_CALENDAR_INFO_MODAL/ATTACH_ABSENCE';

const {item} = generateEntityReducer<AttachAbsenceWithCbType>(PREFIX);

export const actions = item!.actions;
export const reducer = item!.reducer;
