import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {SelectionResultType} from 'components/Chart/types';

import {StateType} from './types';

export const defaultState: StateType = {
    status: RequestStatusInitial,
};

export const actions = {
    request: createAction('COMMON/SHIFT_SIDEBAR/PREPARE_SHIFT_OPERATOR_DATE_PERIOD/REQUEST')<SelectionResultType>(),
    success: createAction('COMMON/SHIFT_SIDEBAR/PREPARE_SHIFT_OPERATOR_DATE_PERIOD/SUCCESS')(),
    fail: createAction('COMMON/SHIFT_SIDEBAR/PREPARE_SHIFT_OPERATOR_DATE_PERIOD/FAIL')<string>(),
    // когда не хватает данных или mode ещё не готов
    skip: createAction('COMMON/SHIFT_SIDEBAR/PREPARE_SHIFT_OPERATOR_DATE_PERIOD/SKIP')(),
};

const reducer = createReducer<StateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.request, () => ({
        status: RequestStatusPending,
    }))
    .handleAction(actions.success, () => ({
        status: RequestStatusSuccessful,
    }))
    .handleAction(actions.skip, () => ({
        status: RequestStatusInitial,
    }))
    .handleAction(actions.fail, (_state, {payload}) => ({
        status: RequestStatusFailed,
        desc: payload,
    }));

export default reducer;
