import {produce} from 'immer';
import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {PFDTablePaginationStatsStateType} from 'reducers/common/shifts/table/stats/pagination/types';

const defaultState: PFDTablePaginationStatsStateType = {
    data: 0,
};

const PREFIX = 'SHIFT_PLANNING/TABLE/STATS/PAGINATION';

export const actions = {
    next: createAction(`${PREFIX}/NEXT_PAGE`)(),
    reset: createAction(`${PREFIX}/RESET_PAGE`)(),
};

export const reducer = createReducer<PFDTablePaginationStatsStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.next, state => produce(state, draft => {
        draft.data += 1;
    }))
    .handleAction(actions.reset, state => produce(state, draft => {
        draft.data = defaultState.data;
    }));
