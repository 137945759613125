import isEqual from 'lodash/isEqual';
import {AnyAction} from 'redux';
import {Reducer, PayloadAction} from 'typesafe-actions';

import {LocalStorage, STORAGE_PREFIX} from 'utils/browserStorage';

import {SetAction} from './types';

const localStorageInstance = new LocalStorage(STORAGE_PREFIX);

export function restore() {
    return Object.keys(localStorage)
        .filter(key => key.includes(STORAGE_PREFIX))
        .reduce((state, fullKey) => {
            const [, ...keyParts] = fullKey.split('.');
            const key = keyParts.join('.');

            state[key] = localStorageInstance.get(key);
            return state;
        }, {} as Indexed);
}

export function cmpDataReducer(prevData: any, action: PayloadAction<string, SetAction>) {
    const {data} = action.payload;

    return isEqual(data, prevData) ? prevData : data;
}

export function prepareNextState<S, A extends AnyAction>(
    storageKey: string,
    reducer: Reducer<S, A>,
) {
    return (state: S, action: A) => {
        let prevState = state;

        if (prevState === undefined) {
            prevState = localStorageInstance.get(storageKey);
        }

        const nextState = reducer(prevState, action);

        if (prevState !== nextState) {
            localStorageInstance.set(storageKey, nextState);
        }

        return nextState;
    };
}
