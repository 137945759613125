import {FieldErrors} from '@filledout/core';
import {setLocale} from 'yup';

export function initializeYupLocale() {
    setLocale({
        // use constant translation keys for messages without values
        mixed: {
            default: () => ({key: 'field_invalid'}),
            required: () => ({key: 'field_required'}),
            oneOf: ({values}) => ({key: 'one_of', params: {values: values.split(', ')}}),
        },
        // use functions to generate an error object that includes the value from the schema
        number: {
            min: ({min}) => ({key: 'field_too_short', params: {min}}),
            max: ({max}) => ({key: 'field_too_big', params: {max}}),
        },
        string: {
            min: ({min}) => ({key: 'field_too_short', params: {min}}),
            max: ({max}) => ({key: 'field_too_big', params: {max}}),
        },
    });
}

// Create types with key = known error, params = known error fields
type DefaultError = {
    key: 'unknown';
}

type MinError = {
    key: 'field_too_short';
    params: {
        min: number;
    };
}

type MaxError = {
    key: 'field_too_big';
    params: {
        max: number;
    };
}

type OneOfError = {
    key: 'one_of';
    params: {
        values: string[];
    };
}

type FieldError = {
    name: DefaultError | MinError | MaxError | OneOfError;
    params: Record<string, unknown>
}

export function getFieldError(errors: FieldErrors): FieldError[] {
    if (!errors || !Array.isArray(errors)) {
        return [];
    }

    return errors.map<FieldError>(err => {
        if (typeof err.name === 'object') {
            return {
                name: err.name,
                params: err.params,
            };
        }

        return {
            name: {key: 'unknown'},
            params: err.params,
        };
    });
}
