import {Space} from 'antd';
import styled from 'styled-components';

import {MENU_COLLAPSED_WIDTH, MENU_REVEALED_WIDTH} from 'components/Menu/Content/MenuTree/consts';
import {colors} from 'utils/styles/colors';

export const AlertHeader = styled(Space).attrs((props: {isCollapsed: boolean}) => props)`
    position: absolute;
    justify-content: center;
    padding: 4px 8px;
    transition: all .2s;
    background-color: ${colors.transparentGreen};

    height: 30px;

    top: 0;
    right: 0;

    left: ${props => props.isCollapsed ? `${MENU_COLLAPSED_WIDTH}px` : `${MENU_REVEALED_WIDTH}px`};
`;
