import {createAction} from 'typesafe-actions';

import {RequestParams} from '../types';

import {Actions} from './types';

export function createEntityActions<
    TData = unknown,
    TRequest extends RequestParams = void,
>(prefix: string): Actions<TData, TRequest> {
    return {
        request: createAction(`${prefix}/REQUEST`)<TRequest>(),
        success: createAction(`${prefix}/SUCCESS`)<TData>(),
        failure: createAction(`${prefix}/FAILURE`)<string>(),
        cancel: createAction(`${prefix}/CANCEL`)<string>(),
        reset: createAction(`${prefix}/RESET`)<void>(),
    };
}
