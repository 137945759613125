import {combineReducers} from 'redux';

import {InternalAbsence} from 'types/absences';
import {generateEntityReducer} from 'utils/reducers/flow';

import {AttachAbsenceStateType} from './types';

const PREFIX = 'COMMON_EMPLOYEES/CARD/ABSENCES/ATTACH';

const {item} = generateEntityReducer<InternalAbsence>(PREFIX);

export const actions = item!.actions;

export default combineReducers<AttachAbsenceStateType>({
    item: item!.reducer,
});
