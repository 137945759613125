import {produce, Draft} from 'immer';
import {createReducer} from 'typesafe-actions';

import {BaseEntityState, CustomReducer, RequestParams} from '../types';

import {Actions, CreateEntityReducerOptions} from './types';

export function createEntityReducer<TData, TRequest extends RequestParams = void>(
    prefix: string,
    actions: Actions<TData, TRequest>,
    options: CreateEntityReducerOptions<TData>,
) {
    return function build<TState extends BaseEntityState<TData> = BaseEntityState<TData>>(): CustomReducer<TState> {
        return createReducer<TState>(options.defaultState as TState)
            .handleAction(
                actions.request,
                (state: TState) => produce(state, draft => {
                    draft.status = RequestStatusPending;

                    if (!options.preventDataCleanupOnRequest) {
                        draft.data = options.defaultState.data as Draft<TData>;
                    }

                    return draft;
                }),
            )
            .handleAction(
                actions.success,
                (state: TState, action: ReturnType<typeof actions.success>) => produce(state, draft => {
                    draft.status = RequestStatusSuccessful;
                    if ('payload' in action) {
                        draft.data = action.payload as Draft<TData>;
                    }

                    return draft;
                }),
            )
            .handleAction(
                actions.failure,
                (state: TState, action: ReturnType<typeof actions.failure>) => produce(state, draft => {
                    draft.status = RequestStatusFailed;

                    if ('payload' in action) {
                        draft.error = action.payload as Draft<string>;
                    }

                    return draft;
                }),
            )
            .handleAction(
                actions.reset,
                () => options.defaultState,
            );
    };
}
