import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {StateType, FiltersType} from './types';

export const defaultState: StateType = null;

export const actions = {
    set: createAction('COMMON/SHIFT_SIDEBAR/SHIFTS_FILTERS/SET')<FiltersType>(),
    reset: createAction('COMMON/SHIFT_SIDEBAR/SHIFTS_FILTERS/RESET')<void>(),
};

const reducer = createReducer<StateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.set, (state, {payload}) => {
        return {
            ...payload,
            ...(payload.datetime_from ? {datetime_from: decodeURIComponent(payload.datetime_from)} : {}),
            ...(payload.datetime_to ? {datetime_to: decodeURIComponent(payload.datetime_to)} : {}),
        };
    })
    .handleAction(actions.reset, () => null);

export default reducer;
