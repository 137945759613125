import React, {memo, useEffect, useMemo, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {RevealedItem} from 'components/Menu/Content/Footer/Settings/FooterMenu/styles';
import {useLocalStorage} from 'hooks/useLocalStorage';
import {blackboxSelector} from 'selectors/common/meta';
import {Domains} from 'types/domains';
import {CookieName, cookies} from 'utils/cookies';
import {prepareHrefWithQuery} from 'utils/router';

import {CONTAINER_ID} from '../../consts';
import {isScriptExist, createScript} from '../../utils';

import {YndxBugContainer} from './styles';
import {Props} from './types';

const language = cookies.get(CookieName.Language);

const BugReport: Props = ({isCollapsed, children}) => {
    const blackbox = useSelector(blackboxSelector);
    const [domain] = useLocalStorage<Domains>('domain', Domains.Taxi);
    const login = useMemo(() =>
        blackbox?.login?.replace(`@${blackbox.uid.domain}`, '')
    , [blackbox]);

    const handleOpen = useCallback(() => {
        const hrefWithDomain = prepareHrefWithQuery(window.location.pathname, {domain});

        window.dispatchEvent(new CustomEvent('butterfly/v1/update-form-data', {
            detail: {
                'answer_url_52113054': hrefWithDomain,
                'answer_short_text_52113048': domain,
                'answer_short_text_52113049': login,
                'lang': language,
            },
        }));
    }, [domain, login]);

    useEffect(() => {
        if (!isScriptExist()) {
            createScript();
        }
    }, []);

    useEffect(() => {
        window.addEventListener('butterfly/open', handleOpen);

        return () => {
            window.removeEventListener('butterfly/open', handleOpen);
        };
    }, [handleOpen]);

    return (
        <YndxBugContainer id={CONTAINER_ID} isCollapsed={isCollapsed}>
            <RevealedItem isCollapsed={isCollapsed}>
                {children}
            </RevealedItem>
        </YndxBugContainer>
    );
};

export default memo(BugReport);
