import isEmpty from 'lodash/isEmpty';

export const userDomainsSelector = (state: GlobalStateType) => state.app.user.data?.domains || [];

export const accessRestrictedSelector = (state: GlobalStateType) => {
    const user = state.app.user.data;

    return Array.isArray(user?.permissions) && isEmpty(user?.permissions);
};

export const userLoadStatusSelector = (state: GlobalStateType) => state.app.user.status;

export const userPermissionsSelector = (state: GlobalStateType) => state.app.user.data?.permissions || [];
