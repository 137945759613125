import {combineReducers} from 'redux';

import focusElement from './focusElement';
import sidebarVisibility from './sidebarVisibility';
import {ViolationsStateType} from './types';

const containerReducer = combineReducers<ViolationsStateType>({
    sidebarVisibility,
    focusElement,
});

export default containerReducer;
