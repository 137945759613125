export enum Domains {
    Taxi = 'taxi',
    Eda = 'eats',
    Lavka = 'lavka',
    Market = 'market',
    Hiring = 'hiring',
    Mediaservice = 'mediaservice'
}

export type DomainsOptions = Array<{
    value: Domains;
    label: string;
}>;
