import moment from 'moment-timezone';

export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const HOURS_IN_HALF_DAY = 12;
export const MILLISECONDS_IN_HOUR = MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
export const MILLISECONDS_IN_HALF_HOUR = MILLISECONDS_IN_HOUR / 2;
export const MILLISECONDS_IN_DAY = HOURS_IN_DAY * MILLISECONDS_IN_HOUR;
export const MINUTES_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR;
export const SECONDS_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE;
export const ROUNDED_HOUR_SUFFIX = '00';
export const DAY_START_TIME = '00:00';
export const MAX_AVAILABLE_YEAR = moment().add(100, 'year');
