import {combineReducers} from 'redux';

import {MultiSkillStateType} from './types';
import updateDraft from './updateDraft';

const shiftDraftReducer = combineReducers<MultiSkillStateType>({
    updateDraft,
});

export default shiftDraftReducer;
