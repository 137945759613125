import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {OperatorsScheduleTypeModifyRequest} from 'types/api/backend-py3/api/v1_operators_schedule_type_modify';

import {AttachScheduleToEmployeeStateType} from './types';

export const defaultState: AttachScheduleToEmployeeStateType = {
    data: undefined,
    status: RequestStatusInitial,
    desc: null,
};

export const actions = {
    requestAttachSchedules: createAction('COMMON_EMPLOYEES/CARD/SCHEDULES/ATTACH/REQUEST')<OperatorsScheduleTypeModifyRequest>(),
    successAttachSchedules: createAction('COMMON_EMPLOYEES/CARD/SCHEDULES/ATTACH/SUCCESS')(),
    failAttachSchedules: createAction('COMMON_EMPLOYEES/CARD/SCHEDULES/ATTACH/FAIL')<string>(),
};

const reducer = createReducer<AttachScheduleToEmployeeStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.requestAttachSchedules, state => ({
        ...state,
        status: RequestStatusPending,
        desc: null,
    }))
    .handleAction(actions.successAttachSchedules, state => ({
        ...state,
        status: RequestStatusSuccessful,
        desc: null,
    }))
    .handleAction(actions.failAttachSchedules, (state: AttachScheduleToEmployeeStateType, action: {payload: string}) => ({
        ...state,
        status: RequestStatusFailed,
        desc: action.payload,
    }));

export default reducer;
