// https://github.yandex-team.ru/taxi/tariff-editor/tree/master/packages/core/src/utils/localization
import Base from '@yandex-int/yandex-tjson';
import {uniq} from 'lodash';

import {RumLogger} from 'utils/rumLogger';

import {FALLBACK_LANGUAGES} from './consts';
import {GetOptions, PrintOptions} from './types';

class TJSON extends Base {
    // Метод get из библиотеки не учитывает keyset
    private _get(key: string, {keyset, number}: GetOptions) {
        const languages = uniq([this.state.language, ...FALLBACK_LANGUAGES]);

        for (const language of languages) {
            const translation = this.storage.key(language, keyset || this.state.keyset, key);

            if (translation?.isPlural && this.i18n[language] && this.i18n[language].plural) {
                return this.i18n[language].plural(
                    number || 0,
                    translation.one,
                    translation.some,
                    translation.many,
                    translation.none);
            }

            if (translation?.form) {
                return translation.form;
            }
        }

        return undefined;
    }

    // нужно переопределить, иначе создаются экземаляры базового класса
    protected clone(withProps: Indexed) {
        const tjson = new TJSON();

        tjson.storage = this.storage;
        tjson.state = Object.assign({}, this.state, withProps);
        return tjson;
    }

    public print(key: string, options: PrintOptions = {}) {
        const {keyset, number} = options;
        const translate = this._get(key, {keyset, number});

        if (typeof translate !== 'string' || !translate || !translate.trim()) {
            if (!import.meta.env.TESTS && keyset === 'origin') {
                const errorMessage = `[i18n] Key ${key} not found`;

                RumLogger.error(new Error(errorMessage), {
                    message: errorMessage,
                    block: 'utils',
                    source: 'localization/tjson',
                    method: 'print',
                    additional: {options, translate, key},
                });
            }

            return key;
        }

        if (options.placeholder) {
            return translate.replace(/([%][\w]+[%])/g, match => {
                return options.placeholder?.[match.substring(1, match.length - 1) as any as number] || '';
            });
        }

        return translate;
    }

    /**
     * Импортирует кейсеты для языка
     * @param {object} data
     * @param {string} [language]
     * @returns {TJSON}
     */
    public import(data: any, language: string) {
        language = language || this.state.language;
        this.storage._langs[language] = data;
        return this;
    }

    /**
     * Возвращает кейсеты для языка
     * @param {string} [language]
     * @returns {json}
     */
    public export(language: string) {
        language = language || this.state.language;
        return this.storage._langs[language];
    }
}

export default TJSON;
