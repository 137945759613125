import {combineReducers} from 'redux';

import {generateEntityReducer} from 'utils/reducers/flow';

import {AddShiftsResponseType, AddShiftsStateType} from './types';

const PREFIX = 'EMPLOYEES/CARD/CHART/SHIFT/ADD';

const {item} = generateEntityReducer<AddShiftsResponseType>(PREFIX);

export const actions = item!.actions;

export default combineReducers<AddShiftsStateType>({
    item: item!.reducer,
});
