import {Descriptions, Badge} from 'antd';
import React, {FC, memo} from 'react';
import {useSelector} from 'react-redux';

import {metaSelector} from 'selectors/common/meta';
import i18n from 'utils/localization';

const Content: FC = () => {
    const meta = useSelector(metaSelector);

    return (
        <Descriptions bordered>
            <Descriptions.Item label={i18n.print('components.menu.version')} span={3}>
                {meta?.version}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.print('components.menu.environment')} span={3}>
                {meta?.env}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.print('components.menu.condition')} span={3}>
                <Badge status="success" text={i18n.print('components.menu.there_are_no_problems')}/>
            </Descriptions.Item>
        </Descriptions>
    );
};

export default memo(Content);
