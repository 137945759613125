import {DateSelectArg} from '@fullcalendar/react';
import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {CreateEventDraftInfoStateType} from './types';

export const defaultState: CreateEventDraftInfoStateType = null;

export const actions = {
    set: createAction('COMMON/CREATE_CALENDAR_INFO_MODAL/DRAFT_INFO/SET')<DateSelectArg>(),
    reset: createAction('COMMON/CREATE_CALENDAR_INFO_MODAL/DRAFT_INFO/RESET')<void>(),
};

const reducer = createReducer<CreateEventDraftInfoStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.set, (state: CreateEventDraftInfoStateType, {payload}) => payload)
    .handleAction(actions.reset, () => null);

export default reducer;
