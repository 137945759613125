import {combineReducers} from 'redux';

import card from './card';
import {CommonEmployeesStateType} from './types';

const reducer = combineReducers<CommonEmployeesStateType>({
    card,
});

export default reducer;
