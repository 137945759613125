import {Typography} from 'antd';
import React, {FC, memo} from 'react';

import {useDocumentationItems} from 'components/Menu/Content/Footer/Settings/FooterMenu/DocumentationWrapper/Content/utils';

import {DocumentationWrapper} from './styles';

const Content: FC = () => {
    const items = useDocumentationItems();

    return (
        <DocumentationWrapper>
            <ul>
                {
                    items.map(item => (
                        <li key={item.key}>
                            <Typography.Link href={item.url}>
                                {item.label}
                            </Typography.Link>
                        </li>
                    ))
                }
            </ul>
        </DocumentationWrapper>
    );
};

export default memo(Content);
