import {combineReducers} from 'redux';

import focusElement from './focusElement';
import sidebarVisibility from './sidebarVisibility';
import {BreaksStateType} from './types';

const containerReducer = combineReducers<BreaksStateType>({
    sidebarVisibility,
    focusElement,
});

export default containerReducer;
