import {produce, Draft} from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import {createSelector} from 'reselect';
import {createAction, createAsyncAction, createReducer} from 'typesafe-actions';

import {makeModel} from 'utils/model';

import {baseDefaultState, DEFAULT_PAGINATION} from './consts';
import {BaseActions, BaseState, EntityReducerOptions, CommonOptions, ActionGeneratorType} from './types';
import {checkPending} from './utils';

export function generateBaseActions<D, P extends Record<string, unknown> = Record<string, unknown>>(prefix: string): ActionGeneratorType<D, P> {
    const asyncActions = createAsyncAction(
        `${prefix}/REQUEST` as string,
        `${prefix}/SUCCESS` as string,
        `${prefix}/FAILED` as string,
    )<P, D, string>();

    const syncActions = {
        reset: createAction(`${prefix}/RESET`)<void>(),
        setPagination: createAction(`${prefix}/SET_PAGINATION`)<Omit<AntPaginationType, 'total'>>(),
        resetPagination: createAction(`${prefix}/RESET_PAGINATION`)<void>(),
        resetPage: createAction(`${prefix}/RESET_PAGE`)<void>(),
        setTotal: createAction(`${prefix}/SET_FULL_COUNT`)<AntPaginationType['total']>(),
    };

    return {
        base: {
            success: asyncActions.success as BaseActions<D, P>['success'],
            request: asyncActions.request as BaseActions<D, P>['request'],
            failed: asyncActions.failure,
            reset: syncActions.reset,
        },
        pagination: {
            setPagination: syncActions.setPagination,
            resetPage: syncActions.resetPage,
            resetPagination: syncActions.resetPagination,
            setTotal: syncActions.setTotal,
        },
    };
}

export function generateBaseReducer<D, P extends Record<string, unknown> = Record<string, unknown>>(prefix: string, defaultState: Partial<BaseState<D>>, options: CommonOptions = {}) {
    const {withPagination, preventDataCleanupOnRequest} = options;
    const {base: baseActions, pagination: paginationActions} = generateBaseActions<D, P>(prefix);
    const defaultValue = {
        ...baseDefaultState,
        ...cloneDeep(defaultState),
        ...(withPagination
            ? {
                pagination: defaultState?.pagination || DEFAULT_PAGINATION,
            }
            : {}
        ),
    };

    let reducer = createReducer<BaseState<D>>(defaultValue)
        .handleAction(
            baseActions.success,
            (state: BaseState<D>, {payload}: {payload: BaseState<D>['data']}) => produce(state, draft => {
                draft.data = payload as Draft<BaseState<D>['data']>;
                draft.status = RequestStatusSuccessful;

                return draft;
            }),
        )
        .handleAction(
            baseActions.failed,
            (state: BaseState<D>, {payload}: {payload: string}) => produce(state, draft => {
                draft.desc = payload;
                draft.status = RequestStatusFailed;

                return draft;
            }),
        )
        .handleAction(
            baseActions.request,
            (state: BaseState<D>, {payload}: {payload: BaseState<D>['data']}) => produce(state, draft => {
                draft.status = RequestStatusPending;

                if (!preventDataCleanupOnRequest) {
                    draft.data = payload as Draft<BaseState<D>['data']>;
                }

                return draft;
            }),
        )
        .handleAction(
            baseActions.reset,
            () => defaultValue,
        );

    if (withPagination && paginationActions) {
        reducer = reducer
            .handleAction(
                paginationActions.setPagination,
                (state: BaseState<D>, {payload}: {payload: BaseState<D>['pagination']}) => produce(state, draft => {
                    if (draft.pagination) {
                        draft.pagination.current = payload?.current || DEFAULT_PAGINATION.current;
                        draft.pagination.pageSize = payload?.pageSize || DEFAULT_PAGINATION.pageSize;
                    }
                    return draft;
                }),
            )
            .handleAction(
                paginationActions.resetPagination,
                (state: BaseState<D>) => produce(state, draft => {
                    if (draft.pagination) {
                        draft.pagination.current = DEFAULT_PAGINATION.current;
                        draft.pagination.pageSize = DEFAULT_PAGINATION.pageSize;
                    }
                    return draft;
                }),
            )
            .handleAction(
                paginationActions.resetPage,
                (state: BaseState<D>) => produce(state, draft => {
                    if (draft.pagination) {
                        draft.pagination.current = DEFAULT_PAGINATION.current;
                    }
                    return draft;
                }),
            )
            // Отдельный экшен нужен для того, чтобы можно было указать сагам реагировать только на setPagination
            .handleAction(
                paginationActions.setTotal,
                (state: BaseState<D>, {payload}: {payload: Undefinable<number>}) => produce(state, draft => {
                    if (draft.pagination) {
                        draft.pagination.total = payload || DEFAULT_PAGINATION.total;
                    }
                    return draft;
                }),
            );
    }

    return {
        actions: {
            ...baseActions,
            ...(paginationActions || {}),
        },
        reducer,
    };
}

export function generateEntityReducer<D, T = object, P extends Record<string, unknown> = Record<string, unknown>>(prefix: string, options?: EntityReducerOptions<D, T>) {
    const {
        withItem = true,
        defaultState,
        withPagination = false,
        preventDataCleanupOnRequest = false,
    } = options || {};

    return {
        items: generateBaseReducer<D[], P>(`${prefix}/ITEMS`, (defaultState?.items || {}), {withPagination, preventDataCleanupOnRequest}),
        ...(withItem ? {item: generateBaseReducer<D, P>(`${prefix}/ITEM`, (defaultState?.item || {}), {withPagination, preventDataCleanupOnRequest})} : {}),
    };
}

export function generateSelectorGroup<T extends GlobalStateType, D>(path: string, options: CommonOptions = {}) {
    const {withPagination} = options;
    const dataPath = `${path}.data`;
    const statusPath = `${path}.status`;
    const descPath = `${path}.desc`;
    const paginationPath = `${path}.pagination`;

    return {
        listSelector: createSelector(
            (state: T) => get(state, dataPath),
            (item: D[]) => item || [],
        ),
        itemSelector: createSelector(
            (state: T) => get(state, dataPath),
            (item: D) => item,
        ),
        statusSelector: createSelector(
            (state: T) => get(state, statusPath),
            (status: RequestStatusType) => status,
        ),
        checkPendingSelector: createSelector(
            (state: T) => get(state, statusPath),
            checkPending,
        ),
        descSelector: createSelector(
            (state: T) => get(state, descPath),
            (error: string) => error,
        ),
        paginationSelector: withPagination
            ? createSelector(
                (state: T) => get(state, paginationPath),
                (item: AntPaginationType) => item || DEFAULT_PAGINATION,
            )
            : createSelector(
                (_state: T) => ({}),
                _res => DEFAULT_PAGINATION,
            ),
    };
}

/*
    * path – Путь от state до reducer
 */
export function generateSelectors<T extends GlobalStateType, D>(statePath: (m: T) => any, options: CommonOptions = {}) {
    const path = makeModel(statePath);
    const itemsPath = `${path}.items`;
    const itemPath = `${path}.item`;

    return {
        items: generateSelectorGroup<T, D[]>(itemsPath, options),
        item: generateSelectorGroup<T, D>(itemPath, options),
    };
}
