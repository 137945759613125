import {AbsenceTypesValuesResponse} from 'types/api/backend-py3/api/v1_absence_types';
import {createEntity} from 'utils/redux/flow/entity';

const entity = createEntity<AbsenceTypesValuesResponse>({
    prefix: 'RESOURCES/ABSENCE_TYPES',
}, state => state.resources.absenceTypes);

export const {
    actions,
    reducer,
    selectors: absenceTypesSelectors,
} = entity;
