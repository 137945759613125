import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {CreateEventModalVisibilityStateType} from './types';

export const defaultState: CreateEventModalVisibilityStateType = false;

export const actions = {
    show: createAction('COMMON/SHIFT_SIDEBAR/FORMS/CREATE_EVENT_MODAL_VISIBILITIES/SHOW')<void>(),
    hide: createAction('COMMON/SHIFT_SIDEBAR/FORMS/CREATE_EVENT_MODAL_VISIBILITIES/HIDE')<void>(),
};

const reducer = createReducer<CreateEventModalVisibilityStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.show, () => true)
    .handleAction(actions.hide, () => false);

export default reducer;
