import {combineReducers} from 'redux';

import {OperatorsTimetableRowObject} from 'types/api/backend-py3/api/v1_operators_timetable_values';
import {generateEntityReducer, generateSelectors} from 'utils/reducers/flow';

import {EmployeeCardChartLoadStateType} from './types';

const PREFIX = 'COMMON/EMPLOYEES/CARD/CHART/LOAD';

const {item} = generateEntityReducer<EmployeeCardChartLoadStateType>(PREFIX);

export const chartLoadSelectors = generateSelectors<GlobalStateType, OperatorsTimetableRowObject>(
    s => s.shiftsOverview.chart.load,
    {withPagination: true},
);

export const actions = item!.actions;

export default combineReducers<EmployeeCardChartLoadStateType>({
    item: item!.reducer,
});
