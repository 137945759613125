import {createReduxIntegration} from '@withease/redux';
import {createEvent} from 'effector';
import {Action} from 'redux';

import {CustomStore} from 'utils/redux/store/types';

export const startReduxInterop = createEvent<CustomStore>();

export const $reduxStore = createReduxIntegration<GlobalStateType, Action, GlobalStateType>({
    setup: startReduxInterop,
});
