import {combineReducers} from 'redux';

import breaks from './breaks';
import fieldsToChange from './fieldsToChange';
import periodHandler from './periodHandler';
import projectActivities from './projectActivities';
import shift from './shift';
import {ShiftsFormsStateType} from './types';
import violations from './violations';

const containerReducer = combineReducers<ShiftsFormsStateType>({
    fieldsToChange,
    breaks,
    projectActivities,
    shift,
    violations,
    periodHandler,
});

export default containerReducer;
