const localStorage = window.localStorage;

interface StorageOptions {
    expire?: number;
}

interface StoreObject<T = any> {
    value: T;
    expire?: Date;
}

abstract class BrowserStorage<T> {
    private _storage: Storage;
    private _isAvailable: boolean;
    private _modelName: string;

    constructor(storage: Storage, name: string) {
        this._storage = storage;
        this._modelName = name || '';
        this._isAvailable = this._isLocalStorageAvailable();
    }

    private _isLocalStorageAvailable() {
        const test = 'test';

        try {
            this._storage.setItem(test, test);
            this._storage.removeItem(test);
            return true;
        } catch (e) {
            return false;
        }
    }

    private _set(key: string, storeObject: any) {
        try {
            const storeValue = JSON.stringify(storeObject);
            const storeKey = this._buildStoreKey(key);

            this._storage.setItem(storeKey, storeValue);
            return true;
        } catch (e) {
            return false;
        }
    }

    private _get(storeKey: string): Nullable<StoreObject<T>> {
        try {
            const storeValue = this._storage.getItem(storeKey);

            return storeValue ? JSON.parse(storeValue) as StoreObject<T> : null;
        } catch (e) {
            return null;
        }
    }

    private _delete(storeKey: string) {
        try {
            this._storage.removeItem(storeKey);
            return true;
        } catch (e) {
            return false;
        }
    }

    private _buildStoreKey(key: string) {
        return this._modelName + '.' + key;
    }

    private _getStoreObject(value: any, options: StorageOptions) {
        const storeObject: StoreObject = {
            value: value,
        };

        // TODO remove ! write tests
        if (options.expire! > 0) {
            storeObject.expire = new Date(new Date().getTime() + options.expire!);
        }

        return storeObject;
    }

    private _isStoreObjectValid(storeObject: Nullable<StoreObject<T>>) {
        return storeObject && (!storeObject.expire || new Date(storeObject.expire).getTime() > new Date().getTime());
    }

    public clear() {
        try {
            this._storage.clear();
            return true;
        } catch (e) {
            return false;
        }
    }

    public clearExpired() {
        // На всякий, чтобы не потереть все подряд
        if (!this._modelName) {
            return;
        }

        Object.keys(this._storage).forEach((key: string) => {
            if (key.indexOf(this._modelName) === 0) {
                // get внутри удалит просроченные ключи
                this.get(key.substr(this._modelName.length + 1));
            }
        });
    }

    public set(key: string, value: any, options?: StorageOptions) {
        if (!this._isAvailable) {
            return false;
        }

        const storedObject = this._getStoreObject(value, options || {});

        return this._set(key, storedObject);
    }

    public get(key: string) {
        if (!this._isAvailable) {
            return;
        }

        const storeKey = this._buildStoreKey(key);
        const storeObject = this._get(storeKey);

        if (this._isStoreObjectValid(storeObject)) {
            return storeObject?.value;
        }

        // Удаляем из локального хранилища неактуальное значение.
        this.delete(key);
    }

    public delete(key: string) {
        if (!this._isAvailable) {
            return false;
        }

        const storeKey = this._buildStoreKey(key);

        return this._delete(storeKey);
    }

    public has(key: string): boolean {
        if (!this._isAvailable) {
            return false;
        }

        const storeKey = this._buildStoreKey(key);

        return !!this._get(storeKey);
    }
}

export class LocalStorage<T = any> extends BrowserStorage<T> {
    constructor(name: string) {
        super(localStorage, name);
    }
}

export const STORAGE_PREFIX = '@app/store';
