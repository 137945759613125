export enum DocumentationTypes {
    ProductsPage = 'productsPage',
    DocsPage = 'docsPage',
    DevelopIdeasPage = 'developIdeasPage',
    ServiceConfigsPage = 'serviceConfigsPage',
}

export type DocumentationItem = {
    key: DocumentationTypes;
    label: string;
    url: string;
};
