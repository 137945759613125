import {combineReducers} from 'redux';

import createEventModalReducer from 'components/Modals/CreateCalendarEvent/reducers';
import {shiftSidebarReducer} from 'components/ShiftSidebar/reducers';
import shiftsMultiselect from 'components/ShiftsMultiselect/reducers';
import {modalsReducer} from 'reducers/common/modals';

import absenceModal from './absenceModal';
import employees from './employees';
import form from './form';
import languages from './languages';
import localization from './localization';
import localStorage from './localStorage';
import meta from './meta';
import metrika from './metrika';
import shifts from './shifts';
import storedFilters from './storedFilters';
import {CommonEntityStateType} from './types';

const reducer = combineReducers<CommonEntityStateType>({
    employees,
    shifts,
    meta,
    localStorage,
    form,
    languages,
    localization,
    shiftSidebar: shiftSidebarReducer,
    createEventModal: createEventModalReducer,
    modals: modalsReducer,
    metrika,
    absenceModal,
    shiftsMultiselect,
    storedFilters,
});

export default reducer;
