import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {StateType} from './types';

const defaultState: StateType = {
    isReady: false,
};

export const actions = {
    ready: createAction('COMMON/LOCALIZATION/READY')(),
    reset: createAction('COMMON/LOCALIZATION/RESET')(),
};

const reducer = createReducer<typeof defaultState, ActionType<typeof actions>>(defaultState)
    .handleAction(
        actions.ready,
        () => ({
            isReady: true,
        }),
    )
    .handleAction(
        actions.reset,
        () => ({
            isReady: false,
        }),
    );

export default reducer;
