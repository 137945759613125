import {produce} from 'immer';
import {ActionType, createAction, createReducer} from 'typesafe-actions';

import SettingsApi from 'api/app/settings';
import {User} from 'types/user';

import {
    AppState,
    PageState,
    Locales,
    ConfigResponseType,
    Timezones,
    TimezoneType,
} from './types';

SettingsApi.initTimezone();

const defaultState: AppState = {
    config: {
        data: null,
        status: RequestStatusInitial,
        desc: null,
    },
    page: {
        id: null,
        isReady: false,
        isFetching: true,
        isFailed: false,
        accessGranted: false,
    },
    locale: Locales.RU,
    user: {
        data: null,
        status: RequestStatusInitial,
        desc: null,
    },
    settings: {
        timezone: SettingsApi.getTimezone(),
    },
    ui: {
        globalSpinnerVisible: false,
    },
};

export const actions = {
    setAsReady: createAction('APP/PAGE/SET_AS_READY')(),
    resetReady: createAction('APP/PAGE/RESET_READY')(),
    configure: createAction('APP/PAGE/CONFIGURE')<Partial<PageState>>(),
    unconfigure: createAction('APP/PAGE/UNCONFIGURE')(),
    removePageSpinner: createAction('APP/PAGE/SPINNER/REMOVE')(),
    openPage: createAction('APP/PAGE/OPEN')(),
    denyAccess: createAction('APP/PAGE/ACCESS/DENY')(),
    setAsFailed: createAction('APP/PAGE/SET_AS_FAILED')(),
    requestConfig: createAction('APP/CONFIG/LOAD/PENDING')(),
    successConfig: createAction('APP/CONFIG/LOAD/SUCCESS')<ConfigResponseType>(),
    failConfig: createAction('APP/CONFIG/LOAD/FAILED')<string>(),
    requestUser: createAction('APP/USER/LOAD/PENDING')(),
    successUser: createAction('APP/USER/LOAD/SUCCESS')<User>(),
    failUser: createAction('APP/USER/LOAD/FAILED')<string>(),
    reset: createAction('APP/RESET')(),
    setTimezone: createAction('APP/SETTINGS/TIMEZONE/SET')<TimezoneType>(),
    resetTimezone: createAction('APP/SETTINGS/TIMEZONE/RESET')(),
    setGlobalLoader: createAction('APP/UI/GLOBAL_LOADER/SET')<boolean>(),
};

// noinspection TypeScriptValidateJSTypes
const reducer = createReducer<AppState, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.setAsReady, state => produce(state, draft => {
        draft.page.isReady = true;
    }))
    .handleAction(actions.resetReady, state => produce(state, draft => {
        draft.page.isReady = false;
    }))
    .handleAction(actions.setGlobalLoader, (state, action) => produce(state, draft => {
        draft.ui.globalSpinnerVisible = action.payload;
    }))
    .handleAction(actions.configure, (state, action) => ({
        ...state,
        page: {
            ...state.page,
            ...action.payload,
        },
    }))
    .handleAction(actions.unconfigure, state => ({...state, page: {...defaultState.page}}))
    .handleAction(actions.removePageSpinner, state => produce(state, draft => {
        draft.page.isFetching = false;
        draft.page.accessGranted = true;
    }))
    .handleAction(actions.openPage, state => produce(state, draft => {
        draft.page.isReady = true;
        draft.page.isFetching = false;
        draft.page.accessGranted = true;
    }))
    .handleAction(actions.denyAccess, state => produce(state, draft => {
        draft.page.isFetching = false;
        draft.page.accessGranted = false;
    }))
    .handleAction(actions.setAsFailed, state => produce(state, draft => {
        draft.page.isFetching = false;
        draft.page.isFailed = true;
    }))
    .handleAction(actions.requestConfig, state => produce(state, draft => {
        draft.config.status = RequestStatusPending;
    }))
    .handleAction(actions.successConfig, (state, action) => produce(state, draft => {
        draft.config.data = action.payload;
        draft.config.status = RequestStatusSuccessful;
        draft.config.desc = null;
    }))
    .handleAction(actions.failConfig, (state, action) => produce(state, draft => {
        draft.config.desc = action.payload;
        draft.config.status = RequestStatusFailed;
    }))
    .handleAction(actions.requestUser, state => produce(state, draft => {
        draft.user.status = RequestStatusPending;
    }))
    .handleAction(actions.successUser, (state, action) => produce(state, draft => {
        draft.user.data = action.payload;
        draft.user.status = RequestStatusSuccessful;
        draft.user.desc = null;
    }))
    .handleAction(actions.failUser, (state, action) => produce(state, draft => {
        draft.user.desc = action.payload;
        draft.user.status = RequestStatusFailed;
    }))
    .handleAction(actions.setTimezone, (state, action) => produce(state, draft => {
        draft.settings.timezone = action.payload;
    }))
    .handleAction(actions.resetTimezone, state => produce(state, draft => {
        draft.settings.timezone = Timezones.MSK;
    }))
    .handleAction(actions.reset, () => {
        return defaultState;
    });

export default reducer;
