import {combineReducers} from 'redux';

import add, {actions as addActions} from './add';
import save, {actions as saveActions} from './save';

const containerReducer = combineReducers({
    add,
    save,
});

export const actions = {
    add: addActions,
    save: saveActions,
};

export default containerReducer;
