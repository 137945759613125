import {yellow} from '@ant-design/colors';

export const colors = {
    white: '#FFFFFF',
    black: '#000000',
    darkerWhite: '#E6EDFF',
    dirtyWhite: '#F0F0F0',

    textPrimary: '#21201F',

    blue: '#3991F7',
    lightBlue: '#E6F7FF',
    textBlue: '#1890FF',
    navigationBlue: 'rgb(232, 236, 246)',
    navigationSubMenuBlue: 'rgba(232, 232, 237)',

    lightGrey: '#D2D0CC',
    hoverGrey: '#E6ECF0',
    borderGrey: '#BCC8D1',
    neutralGrey: '#BFBFBF',
    closeGrey: '#868686',
    anotherNeutralGrey: '#D9D9D9',

    lightYellow: yellow[1],
    yellow: 'rgba(250, 228, 54, 0.55)',
    yellowLogo: '#F8D599',
    warningYellow: '#FAAD14',

    lightRed: 'rgb(255, 183, 172)',
    errorRed: '#ff4d4f',

    lightPurple: 'rgb(180,191,251)',

    lightGreen: 'rgb(154,232,182)',
    transparentGreen: 'rgba(154, 232, 182, 0.2)',

    disableTextColor: 'rgba(0, 0, 0, 0.25)',
    disableBackColor: 'rgb(245, 245, 245)',
    defaultBackColor: 'rgb(240, 242, 246)',

    codeBackground: 'rgb(246, 246, 246)',
};
