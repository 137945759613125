import {Moment} from 'moment-timezone';

import {CreateDraftIntervalType} from 'components/Chart/types';
import {CustomRecord} from 'components/ShiftSidebar/sagas/shift/request/types';
import {Audit, Operator, ScheduleObject} from 'types/api/backend-py3/definitions';
import {ShiftObjectType, ShiftSegment} from 'types/api/backend-py3/definitions/shift';
import {WorkBreaksToShiftFormType, WorkBreakToShiftType} from 'types/breakRules';
import {PeriodPickerValueType} from 'types/common';
import {ProjectActivitiesToShiftFormType, ProjectActivitiesToShiftType} from 'types/projectActivities';

import {ShiftViolation} from './api/backend-py3/definitions/shift_violation';

export type ShiftsTableEmployeeType = {
    shift_id: number;
    yandex_uid: string;
    revision_id: string;
    full_name: string;
    skills?: string[];
    skill?: string; // group
    period: string;
    duration_minutes: number;
    period_margins: PeriodPickerValueType;
    phone?: string;
    telegram?: string;
    fixed?: boolean;
    starts_at?: string;
    expires_at?: string;
    schedule?: string;
    type: ShiftsTypesType;
    is_additional: boolean;
    project_activities: ProjectActivitiesToShiftFormType;
    breaks: WorkBreaksToShiftFormType;
    description?: string;
    state?: ShiftStatesType;
    violation?: ShiftViolation[];
    shift_start?: string;
    frozen?: boolean;
    segments?: ShiftSegment[];
    operator: Operator;
};

export const ShiftsTypes: Record<Capitalize<ShiftObjectType>, ShiftObjectType> = {
    Common: 'common',
    Additional: 'additional',
} as const;

export type ShiftsTypesType = (typeof ShiftsTypes)[keyof typeof ShiftsTypes];

export const ShiftStates = {
    Deleted: 'deleted',
    Ready: 'ready',
    All: 'all',
} as const;

export type ShiftStatesType = (typeof ShiftStates)[keyof typeof ShiftStates];

export type ShiftStateOption = {
    value: ShiftStatesType;
    label: string;
}

export enum ShiftConfirmationMode {
    Confirmed = 'confirmed',
    Unconfirmed = 'unconfirmed',
    All = 'all',
}

export const EditFormFieldKeys = {
    PeriodHandler: 'period_handler',
    PeriodStart: 'period_start',
    PeriodEnd: 'period_end',
    IsAdditional: 'is_additional',
    ProjectActivities: 'project_activities',
    Description: 'description',
    WorkBreaks: 'breaks',
    Operators: 'operators',
    // there are full data of operators
    FullOperators: 'full_operators',
    // Изначально был только один возможный специфичный модификатор "spread_breaks"
    // позже добавили более общий option
    // src/types/api/backend-py3/api/v1_operators_shift_modify.ts OperatorsShiftModifyRequest['option']
    SpreadBreaks: 'spread_breaks',
    Duration: 'duration',
    Option: 'option',
    ShiftID: 'shift_id',
    YandexUID: 'yandex_uid',
    Skill: 'skill',
    RevisionID: 'revision_id',
    Type: 'type',
    Frozen: 'frozen',
    Violations: 'shift_violations',
    Audit: 'audit',
    ActiveSchedule: 'active_schedule',
    IsMultiSkill: 'is_multi_skill',
    IsMultiSkillCreated: 'is_multi_skill_created',
    MultiSkillSegments: 'multi_skill_segments',
    MultiSkillSegmentsDrafts: 'multi_skill_segments_drafts',
} as const;

export type EditFormFieldKeysType = (typeof EditFormFieldKeys)[keyof typeof EditFormFieldKeys];

export const EDIT_OPTIONS = {
    LeaveAsIs: 'leave_as_is',
    SpreadNew: 'spread_new',
    RemoveAll: 'remove_all',
    SaveProvided: 'save_provided',
} as const;

export type EditOptionsType = (typeof EDIT_OPTIONS)[keyof typeof EDIT_OPTIONS];

export const PeriodHandlers = {
    StartAt: 'startAt',
    EndAt: 'endAt',
    Custom: 'custom',
} as const;

export type PeriodHandlersType = (typeof PeriodHandlers)[keyof typeof PeriodHandlers];

export type EditShiftsFormType = Partial<{
    period_handler: PeriodHandlersType;
    period_start: Empty<Moment>;
    period_end: Empty<Moment>;
    period_start_at: Empty<Moment>;
    period_end_at: Empty<Moment>;
    period_start_earlier: number;
    period_end_later: number;
    is_additional: boolean;
    project_activities: ProjectActivitiesToShiftFormType;
    breaks: WorkBreaksToShiftFormType;
    shift_violations: CustomRecord['shift']['shift_violations'];
    operators: string[];
    spread_breaks: boolean;
    description: string;
    option: EditOptionsType;
    duration: Empty<Moment>;
    skill: string;
    audit?: Audit;
    shift_id: number;
    yandex_uid: string;
    revision_id: string;
    type: ShiftsTypesType;
    frozen: boolean;
    active_schedule?: ScheduleObject;
    full_operators?: [Operator];

    // multiskill
    is_multi_skill?: boolean;
    is_multi_skill_created?: boolean;
    multi_skill_segments?: ShiftSegment[];
    multi_skill_segments_drafts?: MultiskillSegmentsDraft[];
}>;

export type MultiskillSegmentsDraft = (Omit<ShiftSegment, 'start' | 'duration_minutes'> & {
    end: Moment;
    duration_minutes: number;
    duration: Moment;
    start: Moment;
    isParallelSkill?: boolean;
    skillAlias?: string;
});

export type SaveShiftOperatorType = {
    shift_id: number;
    yandex_uid: string;
    revision_id: string;
    type?: ShiftsTypesType;
    events?: ProjectActivitiesToShiftType;
    breaks?: WorkBreakToShiftType[];
    description?: string;
};

export type SaveShiftOperatorsType = SaveShiftOperatorType[];

export const ShiftsGranularities = {
    Hour: 60,
    HalfHour: 30,
    FifteenMin: 15,
    FiveMin: 5,
    OneMin: 1,
};

// ShiftsGranularities.FifteenMin
// showTime prop only accepts from 0 to 60 numbers (union type)
export const MINIMAL_GRANULARITY = 15 as const;

export type ShiftsGranularityType = (typeof ShiftsGranularities)[keyof typeof ShiftsGranularities];

export type DateTimeDataType = {
    date: string;
    time: string;
    value: number;
    skill?: string;
};

export type OperatorsTimetableLoadOneRequest = {
    datetime_from: string;
    datetime_to: string;
    yandex_uid: string;
}

export type DateInterval = {
    start: Moment;
    end: Moment;
};

export type ShiftsBulkSelectedDraft = {
    rowDraft: CreateDraftIntervalType;
    yandex_uid: string;
};

export type ShiftsBulkSelected = {
    // В дне может быть несколько смен
    ids?: number[];
    date: Moment;
    // Периоды всех смен внутри ShiftsDayItem
    periods?: Moment[][];
    id?: string,
} & Partial<ShiftsBulkSelectedDraft>;

export type RevertShiftChanges = {
    revert?: VoidFunction;
};

export type ShiftPeriod = {
    start: Moment,
    end: Moment,
    durationMinutes: number,
};

export type StatsDiffs = {
    skill: string;
    data: Record<string, number>;
}[];
