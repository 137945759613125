import React, {Suspense} from 'react';

import {CenterLoader} from 'utils/spinWrapper';

export default function <P extends object> (Component: React.ComponentType<P>): React.FC<P> {
    return props => (
        <Suspense fallback={<CenterLoader/>}>
            <Component {...props}/>
        </Suspense>
    );
};
