import store from 'reduxStore';

import {actions as appActions} from './app';
import {taskCreateActions} from './common/employees/card/shifts/setup/task/create';
import {taskPollActions} from './common/employees/card/shifts/setup/task/poll';
import {actions as routerActions} from './router';

export const pure = {
    router: routerActions,
    taskCreateActions,
    taskPollActions,
    app: appActions,
};

export const bound = store.bindActions(pure);
