import {createEntity} from 'utils/redux/flow/entity';

import {ViolationsTypesSuggest} from './types';

const entity = createEntity<ViolationsTypesSuggest>({
    prefix: 'RESOURCES/VIOLATIONS',
}, state => state.resources.violations);

export const {
    actions,
    reducer,
    selectors: violationsSelectors,
} = entity;
