import {useQuery} from '@tanstack/react-query';

import {ConfigsAPI} from 'api/configs';
import {WFMFrontendHeaderAlertSettings} from 'types/api/backend-py3/definitions/configs';

export const useHeaderAlertSettings = () => {
    return useQuery({
        queryKey: ['WFM_FRONTEND_HEADER_ALERT_SETTINGS'],

        queryFn: async () => {
            const response = await ConfigsAPI.request(['WFM_FRONTEND_HEADER_ALERT_SETTINGS']);

            if (!response.data.configs) {
                return undefined;
            }

            const config = response.data.configs[0] as WFMFrontendHeaderAlertSettings;

            return config.body;
        },

        gcTime: Infinity,
        refetchOnMount: false,
    });
};
