import {combineReducers} from 'redux';

import {generateEntityReducer} from 'utils/reducers/flow';

import {ModifyBulkFormsType, ModifyBulkFormsStateType} from './types';

const PREFIX = 'COMMON_EMPLOYEES/CARD/TABLE_BULK/FORM_TYPE';

const {item} = generateEntityReducer<ModifyBulkFormsType>(PREFIX, {
    defaultState: {
        item: {
            data: 'tags',
        },
    },
});

export const actions = item!.actions;

export default combineReducers<ModifyBulkFormsStateType>({
    item: item!.reducer,
});
