import axios, {InternalAxiosRequestConfig} from 'axios';

import {getDomain} from 'utils/domain';

function addHeaders(config: InternalAxiosRequestConfig) {
    config.headers['X-WFM-Domain'] = getDomain();

    return config;
}

export const nodeApi = axios.create({baseURL: '/api/node/'});
export const adminApi = axios.create({baseURL: '/api/node/admin'});
export const healthchecksApi = axios.create({baseURL: '/healthchecks'});

nodeApi.interceptors.request.use(addHeaders);
adminApi.interceptors.request.use(addHeaders);
healthchecksApi.interceptors.request.use(addHeaders);
