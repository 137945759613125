import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {StateType} from './types';

export const defaultState: StateType = {};

export const actions = {
    show: createAction('COMMON_EMPLOYEES/CARD/SCHEDULE/MODAL/SHOW')<StateType['item']>(),
    create: createAction('COMMON_EMPLOYEES/CARD/SCHEDULE/MODAL/CREATE')<StateType['newItem']>(),
    reset: createAction('COMMON_EMPLOYEES/CARD/SCHEDULE/MODAL/RESET')(),
};

const reducer = createReducer<StateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.show, (_state, {payload}) => ({
        newItem: undefined,
        item: payload,
    }))
    .handleAction(actions.create, (_state, {payload}) => ({
        item: undefined,
        newItem: payload,
    }))
    .handleAction(actions.reset, () => defaultState);

export default reducer;
