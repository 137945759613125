import {Tooltip, Typography} from 'antd';
import React, {memo} from 'react';

import {cropText} from 'utils/cropText';

import {Props} from './types';

const CroppedText: Props = ({
    text,
    maxLength,
    className,
    as: Component = Typography.Text,
    style,
    fontSize,
    noTooltip = false,
    strong,
}) => {
    if (text.length <= maxLength) {
        return (
            <Component strong={strong} className={className} style={style}>
                {text}
            </Component>
        );
    }

    return (
        <Tooltip title={text} open={noTooltip ? false : undefined}>
            <Component className={className} style={{...style, fontSize}} strong={strong}>
                {cropText(text, {maxLength})}
            </Component>
        </Tooltip>
    );
};

export default memo(CroppedText);
