import {TranslateStaffAbsencesMap} from 'reducers/resources/staffAbsencesConfig/types';
import {createEntity} from 'utils/redux/flow/entity';

const entity = createEntity<TranslateStaffAbsencesMap>({
    prefix: 'RESOURCES/STAFF_ABSENCES_CONFIG',
}, state => state.resources.staffAbsencesConfig);

export const {
    actions,
    reducer,
    selectors: staffAbsencesConfigSelectors,
} = entity;
