import styled from 'styled-components';

import {RevealedItem} from 'components/Menu/Content/Footer/Settings/FooterMenu/styles';

import {MARGIN_PX_BOTTOM} from '../../consts';

export const YndxBugContainer = styled.div<{isCollapsed: boolean}>`
    position: absolute;
    display: block;

    bottom: ${MARGIN_PX_BOTTOM}px;
    width: ${props => props.isCollapsed ? '70px' : '260px'};
    height: 44px;

    cursor: pointer;

    & .YndxBug{
        bottom: ${MARGIN_PX_BOTTOM}px !important;
    }
    & .YndxBug-Icon {
        opacity: 0;
        width: ${props => props.isCollapsed ? '70px' : '260px'};
    }
    & .YndxBug-Body, .YndxBug-Paranja{
        margin-bottom: -${MARGIN_PX_BOTTOM}px !important;
    }
    & > span:first-child {
        font-size: 16px;
        margin-left: 24px;
    }
    & span{
        transition: all .2s ease;
    }

    ${({isCollapsed}) =>
        isCollapsed
            ? `
                & .ant-typography  {
                    width: 0;
                    opacity: 0;
                }
            `
            : `
                & .ant-typography  {
                    width: auto;
                    opacity: 1;
                }
            `}

    &:hover ${RevealedItem} {
        background-color: rgba(0, 0, 0, 0.06);
    }
`;
