import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {BreaksVisibilityStateType} from './types';

export const defaultState: BreaksVisibilityStateType = false;

export const actions = {
    show: createAction('COMMON/SHIFT_SIDEBAR/FORMS/BREAKS/VISIBILITY/SHOW')<void>(),
    hide: createAction('COMMON/SHIFT_SIDEBAR/FORMS/BREAKS/VISIBILITY/HIDE')<void>(),
};

const reducer = createReducer<BreaksVisibilityStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.show, () => true)
    .handleAction(actions.hide, () => false);

export default reducer;
