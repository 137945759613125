import {preloader as domainsPreloader} from './domains';
import {preloader as localizationsPreloader} from './localizations';
import {preloader as momentPreloader} from './moment';

export function syncAppPreloader() {
    domainsPreloader();
    localizationsPreloader();
    momentPreloader();
}

export async function asyncAppPreloader() {
    try {
        // TODO: async data
    } catch (e) {
        console.warn('error app preloader', e);
    }
}
