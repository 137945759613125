import {Typography} from 'antd';
import React, {FC, memo} from 'react';

import {cookies, CookieName} from 'utils/cookies';

const language = cookies.get(CookieName.Language);
const CurrentLanguage: FC = () => {
    return (
        <Typography.Text>
            {language}
        </Typography.Text>
    );
};

export default memo(CurrentLanguage);
