import {combineReducers} from 'redux';

import {generateEntityReducer} from 'utils/reducers/flow';

import {StateType} from './types';

const PREFIX = 'COMMON_EMPLOYEES/CARD/TABLE_BULK/SKILLS_EMPLOYEES';

const {items} = generateEntityReducer<StateType>(PREFIX);

export const actions = items.actions;

export default combineReducers<StateType>({
    items: items.reducer,
});
