import {combineReducers} from 'redux';

import create from './create';
import poll from './poll';
import {SetupShiftsTaskStateType} from './types';

export default combineReducers<SetupShiftsTaskStateType>({
    create,
    poll,
});
