import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {ProjectActivitiesFocusElementStateType} from './types';

export const defaultState: ProjectActivitiesFocusElementStateType = null;

export const actions = {
    set: createAction('COMMON/SHIFT_SIDEBAR/FORMS/PROJECT_ACTIVITIES/FOCUS_ELEMENT/SET')<ProjectActivitiesFocusElementStateType>(),
    reset: createAction('COMMON/SHIFT_SIDEBAR/FORMS/PROJECT_ACTIVITIES/FOCUS_ELEMENT/RESET')<void>(),
};

const reducer = createReducer<ProjectActivitiesFocusElementStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.set, (state: ProjectActivitiesFocusElementStateType, {payload}) => payload)
    .handleAction(actions.reset, () => null);

export default reducer;
