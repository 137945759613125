import {combineReducers} from 'redux';

import {generateEntityReducer} from 'utils/reducers/flow';

import {ModifyEmployeeResponseType, ModifyEmployeeStateType} from './types';

const PREFIX = 'COMMON_EMPLOYEES/CARD/MODIFY';

const {item} = generateEntityReducer<ModifyEmployeeResponseType>(PREFIX);

export const actions = item!.actions;

export default combineReducers<ModifyEmployeeStateType>({
    item: item!.reducer,
});
