import {combineReducers} from 'redux';

import formType from './formType';
import modifyBulk from './modifyBulk';
import skillsEmployees from './skillsEmployees';
import {TableBulkStateType} from './types';

const reducer = combineReducers<TableBulkStateType>({
    modifyBulk,
    formType,
    skillsEmployees,
});

export default reducer;
