import HighchartsChart from 'highcharts/highcharts';
import HighchartsGantt from 'highcharts/highcharts-gantt';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDraggablePointsModule from 'highcharts/modules/draggable-points';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment-timezone';

import i18n from 'utils/localization';

HighchartsMore(HighchartsGantt);
HighchartsMore(HighchartsChart);

HighchartsDraggablePointsModule(HighchartsGantt);

const updateLocales = () => {
    HighchartsGantt.setOptions({
        lang: {
            loading: i18n.print('common.loading'),
            months: moment.months(),
            shortMonths: moment.monthsShort(),
            weekdays: moment.weekdays(),
            shortWeekdays: moment.weekdaysShort(),
            rangeSelectorFrom: i18n.print('common.range_selection_from'),
            rangeSelectorTo: i18n.print('common.range_selection_to'),
            rangeSelectorZoom: i18n.print('common.range_selection_zoom'),
            downloadPNG: i18n.print('common.download_png'),
            downloadJPEG: i18n.print('common.download_jpeg'),
            downloadPDF: i18n.print('common.download_pdf'),
            downloadSVG: i18n.print('common.download_svg'),
            printChart: i18n.print('common.print_chart'),
        },
    });
};

export {HighchartsGantt, HighchartsReact, HighchartsChart, updateLocales};
