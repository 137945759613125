import React, {memo, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {usePassportHost} from 'components/Menu/Content/Footer/Account/usePassportHost';
import {EnvTypes} from 'reducers/app/types';
import envSelector from 'selectors/app/configuration/envSelector';
import {blackboxSelector} from 'selectors/common/meta';

import {
    AVATAR_TEMPLATE_ID,
    BASE_AVATAR_URL_PROD,
    BASE_AVATAR_URL_TEST,
    BASE_PASSPORT_URL_YA_TEAM,
} from '../consts';
import {
    InfoWrapper,
    AvaItem,
    Ava,
    AccountNameWrapper,
    AccountName,
    Login,
} from '../styles';

import {Props} from './types';

const Info: Props = ({
    isCollapsed,
    loginMaxLength,
    nameMaxLength,
    handleClick,
}) => {
    const env = useSelector(envSelector);
    const blackbox = useSelector(blackboxSelector);
    const passportHost = usePassportHost();

    const isProd = env === EnvTypes.prod;
    const iconUrl = useMemo(
        () => {
            if (passportHost === BASE_PASSPORT_URL_YA_TEAM && !!blackbox?.login) {
                return `https://center-robot.yandex-team.ru/api/v1/user/${blackbox?.login}/avatar.jpg`;
            }

            if (isProd) {
                return `https://${BASE_AVATAR_URL_PROD}/get-yapic/${AVATAR_TEMPLATE_ID}/islands-68`;
            }

            return `https://${BASE_AVATAR_URL_TEST}/get-yapic/${AVATAR_TEMPLATE_ID}/islands-68`;
        },
        [isProd, passportHost, blackbox],
    );

    // Есть вариант доставания Имени и Фамилии из attributes (индексы 28 и 27)
    const fullName = useMemo(
        () => {
            if (!blackbox) {
                return '';
            }
            if (blackbox.attributes?.['28'] || blackbox.attributes?.['27']) {
                const secondName = blackbox.attributes?.['28'] || '';
                const firstName = blackbox.attributes?.['27'] || '';

                return [secondName, firstName].filter(name => !!name).join(' ');
            }
            return blackbox.regname || '';
        },
        [blackbox],
    );
    const login = blackbox ? blackbox?.login?.replace(`@${blackbox?.uid?.domain}`, '') : '';

    return (
        <InfoWrapper
            size={0}
            isCollapsed={isCollapsed || false}
            onClick={handleClick}
        >
            <AvaItem>
                <Ava
                    src={iconUrl.replace(
                        AVATAR_TEMPLATE_ID,
                        blackbox?.display_name?.avatar?.default || 'not',
                    )}
                >
                    {fullName ? fullName[0] : null}
                </Ava>
            </AvaItem>

            <AccountNameWrapper>
                <AccountName
                    text={fullName || ''}
                    maxLength={nameMaxLength || 24}
                />

                <Login
                    text={login || ''}
                    maxLength={loginMaxLength || 32}
                />
            </AccountNameWrapper>
        </InfoWrapper>
    );
};

export default memo(Info);
