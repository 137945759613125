import {combineReducers} from 'redux';

import {reducer as employeesReducer} from './employees';
import modalPA from './modalPA';
import pa from './pa';
import shifts from './shifts';
import ui from './ui';

const containerReducer = combineReducers({
    employees: employeesReducer,
    shifts,
    ui,
    modalPA,
    pa,
});

export default containerReducer;
