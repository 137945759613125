import {ActionType, createAction, createReducer, PayloadAction} from 'typesafe-actions';

import {LocalStorageStateType, SetAction} from './types';
import {prepareNextState, cmpDataReducer, restore} from './utils';

export const defaultState: LocalStorageStateType = restore();

export const actions = {
    set: createAction('COMMON/LOCAL_STORAGE/SET')<SetAction>(),
};

const reducer = createReducer<LocalStorageStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.set, (state: LocalStorageStateType, action: PayloadAction<string, SetAction>) => {
        if (state === undefined) {
            return restore();
        }

        const {key} = action.payload;
        const prevData = state?.[key];
        const nextData = prepareNextState(key, cmpDataReducer)(prevData, action);

        if (prevData === nextData) {
            return state;
        }

        return {
            ...state,
            [key]: nextData,
        };
    });

export default reducer;
