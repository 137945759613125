import styled from 'styled-components';

export const SettingsFormWrapper = styled.div`
    width: 100%;

    div {
        margin-bottom: 8px;
    }

    div:last-child {
        margin-bottom: 0;
    }
`;
