import {Result} from 'antd';
import React, {useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {useVersionWarning} from 'components/App/hooks/useVersionWarning';
import Error from 'components/Error';
import Menu from 'components/Menu';
import MainHeaderAlert from 'components/Page/MainHeaderAlert';
import {useLocalStorage} from 'hooks/useLocalStorage';
import routes from 'routes';
import appPreloadingStatusSelector from 'selectors/app/appPreloadingStatusSelector';
import {accessRestrictedSelector} from 'selectors/app/user';
import {updateLocales} from 'utils/highcharts';
import i18n from 'utils/localization';
import {CenterLoader} from 'utils/spinWrapper';

import {LayoutWrapper, LayoutContent, LayoutBody} from './styles';

export default () => {
    const appPreloadingStatus = useSelector(appPreloadingStatusSelector);
    const accessRestricted = useSelector(accessRestrictedSelector);
    const [isCollapsed, setCollapsedStatus] = useLocalStorage<boolean>('menu.is_collapsed', true);
    const toggleMenu = useCallback(() => {
        setCollapsedStatus(!isCollapsed);
    }, [isCollapsed, setCollapsedStatus]);

    useEffect(() => {
        updateLocales();
    }, []);

    useVersionWarning();

    if (accessRestricted) {
        return (
            <Result
                status="403"
                title={i18n.print('components.app.access_is_denied')}
                subTitle={i18n.print('components.app.access_requires')}
            />
        );
    }

    return (
        <LayoutWrapper>
            {appPreloadingStatus === RequestStatusSuccessful && (
                <Menu
                    isCollapsed={isCollapsed}
                    toggleMenu={toggleMenu}
                />
            )}
            <LayoutBody>
                {appPreloadingStatus === RequestStatusSuccessful && (
                    <MainHeaderAlert isCollapsed={isCollapsed}/>
                )}
                <LayoutContent isCollapsed={isCollapsed}>
                    {appPreloadingStatus === RequestStatusPending && (
                        <CenterLoader/>
                    )}
                    {appPreloadingStatus === RequestStatusFailed && (
                        <Error/>
                    )}
                    {appPreloadingStatus === RequestStatusSuccessful && routes}
                </LayoutContent>
            </LayoutBody>
        </LayoutWrapper>
    );
};
