import {createSelector} from 'reselect';

import {Key} from 'hooks/useLocalStorage/types';

export function makeLocalStorageSelector<T>(key: Key, defaultValue?: T) {
    return createSelector(
        (state: GlobalStateType): T => state.common?.localStorage?.[key],
        (state: GlobalStateType) => state,
        value => {
            return (value ?? defaultValue) as T;
        },
    );
}
