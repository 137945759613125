import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {BreaksFocusElementStateType} from './types';

export const defaultState: BreaksFocusElementStateType = null;

export const actions = {
    set: createAction('COMMON/SHIFT_SIDEBAR/FORMS/VIOLATIONS/FOCUS_ELEMENT/SET')<BreaksFocusElementStateType>(),
    reset: createAction('COMMON/SHIFT_SIDEBAR/FORMS/VIOLATIONS/FOCUS_ELEMENT/RESET')<void>(),
};

const reducer = createReducer<BreaksFocusElementStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.set, (state: BreaksFocusElementStateType, {payload}) => payload)
    .handleAction(actions.reset, () => null);

export default reducer;
