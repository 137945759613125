import {Row, Col, Select} from 'antd';
import React, {useCallback, memo, FC} from 'react';
import {useSelector} from 'react-redux';

import {useLocalStorage} from 'hooks/useLocalStorage';
import {domainsOptionsSelector} from 'selectors/resources/domains/domains';
import {Domains} from 'types/domains';
import {setNewDomain} from 'utils/cookies';
import i18n from 'utils/localization';
import {pushWithQuery} from 'utils/router';
import {FULL_WIDTH_STYLE, ROW_GUTTER} from 'utils/styles';

export const DOMAINS_SELECT_POPUP_HEIGHT = 400;

const DomainSelect: FC = () => {
    const domains = useSelector(domainsOptionsSelector);
    const [domain, setDomain] = useLocalStorage<Domains>('domain', Domains.Taxi);

    const handleDomainChange = useCallback((value: Domains) => {
        setDomain(value);
        pushWithQuery(window.location.pathname, {}, {clearAll: true});
        setNewDomain(value);
        window.location.reload();
    }, [setDomain]);

    return (
        <Row gutter={ROW_GUTTER} align="middle">
            <Col span={8}>
                {i18n.print('common.domain')}
            </Col>
            <Col span={16}>
                <Select
                    value={domain}
                    options={domains}
                    onChange={handleDomainChange}
                    listHeight={DOMAINS_SELECT_POPUP_HEIGHT}
                    style={FULL_WIDTH_STYLE}
                />
            </Col>
        </Row>
    );
};

export default memo(DomainSelect);
