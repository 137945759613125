export const AVATAR_TEMPLATE_ID = 'IMAGE_ID';
export const BASE_AVATAR_URL_TEST = 'avatars.mdst.yandex.net';
export const BASE_AVATAR_URL_PROD = 'avatars.mds.yandex.net';
export const BASE_AVATAR_URL_YA_TEAM = 'avatars.mds.yandex-team.net';

export const BASE_PASSPORT_URL_TEST = 'passport-test.yandex.ru';
export const BASE_PASSPORT_URL_PROD = 'passport.yandex.ru';
export const BASE_PASSPORT_URL_YA_TEAM = 'passport.yandex-team.ru';

export const YANDEX_TEAM_DOMAIN = 'yandex-team';

// смотреть в configs/default.blackbox
export const ATTRIBUTES = {
    FIRST_NAME: 27,
    LAST_NAME: 28,
    LANG: 34,
    EMAIL: 1008,
};
