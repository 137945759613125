import {combineReducers} from 'redux';

import {reducer as attachAbsenceReducer} from './attachAbsence';
import attachShiftSegment from './attachShiftSegment';
import chartSelection from './chartSelection';
import draftInfo from './draftInfo';
import eventsForm from './eventsForm';
import {CreateEventModalStateType} from './types';

const createEventModalReducer = combineReducers<CreateEventModalStateType>({
    eventsForm,
    draftInfo,
    attachAbsence: attachAbsenceReducer,
    chartSelection,
    attachShiftSegment,
});

export default createEventModalReducer;
