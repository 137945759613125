import {Tooltip} from 'antd';
import React, {memo, useMemo} from 'react';

import FeedbackWrapper from 'components/Menu/Content/Footer/Settings/FooterMenu/FeedbackWrapper';
import {getMenu} from 'components/Menu/Content/MenuTree/consts';
import {MenuServerItemTypes} from 'components/Menu/types';

import {MENU_FOOTER_ICONS_MAP, MENU_FOOTER_WRAPPERS_MAP} from './consts';
import {
    RevealedListWrapper,
    ItemLabel,
    RevealedItemWrapper,
} from './styles';
import {FooterMenuItemType, Props} from './types';

const FooterMenu: Props = ({isCollapsed}) => {
    const footer = useMemo(() => {
        return Object.values((getMenu()) as unknown as MenuServerItemTypes)
            .reduce<FooterMenuItemType[]>((result, item) => {
                if (item.isFooter) {
                    result.push({
                        icon: item.icon && MENU_FOOTER_ICONS_MAP[item.icon]
                            ? MENU_FOOTER_ICONS_MAP[item.icon]
                            : undefined,
                        label: item.title,
                        ItemWrapper: item.itemWrapper ? MENU_FOOTER_WRAPPERS_MAP[item.itemWrapper] : FeedbackWrapper,
                    });
                }

                return result;
            }, [] as FooterMenuItemType[]);
    }, []);

    return (
        <RevealedListWrapper>
            {footer.map((item, index) => {
                const icon = isCollapsed
                    ? (
                        <Tooltip title={item.label} placement="left">
                            {item.icon}
                        </Tooltip>
                    )
                    : item.icon;

                let content = (
                    <>
                        {icon}

                        <ItemLabel>
                            {item.label}
                        </ItemLabel>
                    </>
                );

                const {ItemWrapper} = item;

                if (ItemWrapper) {
                    content = (
                        <ItemWrapper isCollapsed={isCollapsed}>
                            {content}
                        </ItemWrapper>
                    );
                }

                return (
                    <RevealedItemWrapper
                        key={`${item.label}_${index}`}
                        isCollapsed={isCollapsed}
                    >
                        {content}
                    </RevealedItemWrapper>
                );
            })}
        </RevealedListWrapper>
    );
};

export default memo(FooterMenu);
