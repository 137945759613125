import {Space, Avatar, Button} from 'antd';
import styled from 'styled-components';

import CroppedText from 'components/Text/CroppedText';
import {colors} from 'utils/styles/colors';

export const AccountWrapper = styled.div`
    height: 52px;
    width: 100%;
    box-shadow: inset 0 1px 0 0 ${colors.dirtyWhite};
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Ava = styled(Avatar)`
    height: 28px;
    width: 28px;
    font-size: 28px;
    line-height: 28px;
`;

export const AvaItem = styled.div`
    height: 32px;
    width: 32px;
    padding: 2px;
`;

export const AccountNameWrapper = styled.div`
    margin-left: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    transition: all .2s;
`;

export const InfoWrapper = styled(Space)<{isCollapsed: boolean}>`
    height: 52px;
    width: 100%;
    padding: 10px;
    transition: all .2s;

    ${({isCollapsed}) =>
        isCollapsed
            ? `
                padding: 10px 19px;

                ${AccountNameWrapper} {
                    width: 0;
                    opacity: 0;
                }
                `
            : `
                padding: 10px 16px;

                ${AccountNameWrapper} {
                    width: auto;
                    opacity: 1;
                }
            `}

    &:hover {
        cursor: pointer;
        background: rgba(0,0,0,.018);
    }
`;

export const AccountName = styled(CroppedText)`
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Login = styled(CroppedText)`
    font-size: 12px;
    line-height: 16px;
    color: ${colors.neutralGrey};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const AccountButton = styled(Button)`
    height: 40px;
    text-align: left;
    padding-left: 18px;
    padding-right: 18px;
`;
