import {combineReducers} from 'redux';

import {actions as absenceTypesActions, reducer as absenceTypes} from './absenceTypes';
import {actions as breakRulesActions, reducer as breakRules} from './breakRules';
import {actions as departmentsActions, reducer as departments} from './departments';
import {actions as domainsActions, reducer as domainsReducer} from './domains';
import {actions as projectActivitiesActions, reducer as projectActivities} from './projectActivities';
import {actions as schedulesActions, reducer as schedules} from './schedules';
import {actions as skillsActions, reducer as skills} from './skills';
import {actions as staffAbsencesConfigActions, reducer as staffAbsencesConfig} from './staffAbsencesConfig';
import {ResourcesStateType} from './types';
import {actions as violationsActions, reducer as violationsReducer} from './violations';

const reducer = combineReducers<ResourcesStateType>({
    breakRules,
    departments,
    projectActivities,
    schedules,
    skills,
    absenceTypes,
    staffAbsencesConfig,
    violations: violationsReducer,
    domains: domainsReducer,
});

export const actions = {
    breakRules: breakRulesActions,
    departments: departmentsActions,
    projectActivities: projectActivitiesActions,
    schedules: schedulesActions,
    skills: skillsActions,
    absenceTypes: absenceTypesActions,
    domains: domainsActions,
    violations: violationsActions,
    staffAbsencesConfig: staffAbsencesConfigActions,
};

export default reducer;
