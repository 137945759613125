import {combineReducers} from 'redux';

import formType from './formType';
import request from './request';
import {EventsFormStateType} from './types';

const containerReducer = combineReducers<EventsFormStateType>({
    formType,
    request,
});

export default containerReducer;
