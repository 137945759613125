import uniqueId from 'lodash/uniqueId';

import {EnvTypes} from 'reducers/app/types';
import store from 'reduxStore';
import envSelector from 'selectors/app/configuration/envSelector';

export enum DataCYPaths {
    Components = 'component',
    Absences = 'absences',
    Approvals = 'approvals',
    ApprovalsAbsences = 'approvals-absences',
    Employees = 'employees',
    OperatorStatistic = 'operator-statistic',
    ProjectActivities = 'project-activities',
    Schedules = 'schedules',
    DisciplineOverview = 'discipline-overview',
    NanimatorCandidates = 'nanimator-candidates',
    ShiftsOverview = 'shifts-overview',
    ShiftsPlanning = 'shifts-planning',
    ShiftsModule = 'shifts-module',
    Skills = 'skills',
    WorkBreaks = 'work-breaks',
    Roles = 'roles',
    Predictions = 'predictions',
    Tags = 'tags',

    Components_SchedulePreview = 'components_schedule-preview',
    Components_Employee = 'components_employee',
    Components_ChipsFilters = 'components_chips-filters',
}

const files: Record<string, Set<string>> = {};

// работает только для не продакшн окружения
export const dataCY = (mainPath: DataCYPaths, postfix = '') => {
    // Мемоизация на уровне файла позволяет избежать дублирования имен в разных файлах
    const filename = uniqueId();

    files[filename] = new Set();

    return (controlName?: string) => {
        const appEnv = envSelector(store.getState());

        if (appEnv === EnvTypes.prod) {
            return '';
        }

        const name = `${mainPath}${postfix ? `-${postfix}` : ''}${controlName ? `-${controlName}` : ''}`;

        Object.entries(files).forEach(([key, set]) => {
            if (filename !== key && set.has(name)) {
                // console.error(`Data CY name '${name}' is already exists, pls rename it!`); // TODO uncomment
            }
        });
        files[filename].add(name);

        return name;
    };
};
