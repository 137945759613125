import {UserDomainsInfoV2} from 'types/api/backend-py3/api/v2_idm_user_info';

export const extractAvailableDomains = (user: UserDomainsInfoV2): string[] => {
    return Object.keys(user.domains || {});
};

export const extractPermissions = (user: UserDomainsInfoV2, currentDomain: string): string[] => {
    const domains = extractAvailableDomains(user);

    if (domains.length > 0) {
        const info = user.domains?.[currentDomain];

        return info?.permissions || [];
    }
    return [];
};
