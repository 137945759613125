import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {IntervalDifferenceType, SelectionResultType, ReducedShiftMetaType} from 'components/Chart/types';
import {EditOptionsType} from 'types/shifts';

import {EmployeeChartUIStateType} from './types';

export const defaultState: EmployeeChartUIStateType = {
    selectedShift: undefined,
    isDetailEditFormOpen: false,
    isDetailCreateFormOpen: false,
    interval: {},
    selectionData: {
        period: undefined,
        rowsIds: [],
    },
};

export const actions = {
    moveInterval: createAction('EMPLOYEES/CARD/CHART/UI/INTERVAL/MOVE')<{option: EditOptionsType}>(),
    setMovedInterval: createAction('EMPLOYEES/CARD/CHART/UI/INTERVAL/SET_MOVED_INTERVAL')<Pick<IntervalDifferenceType, 'periodDifference'>>(),
    clearMovedInterval: createAction('EMPLOYEES/CARD/CHART/UI/INTERVAL/CLEAR_MOVED_INTERVAL')(),
    selectShift: createAction('EMPLOYEES/CARD/CHART/UI/SHIFT/SELECT')<Undefinable<ReducedShiftMetaType[]>>(),
    openShiftsUIEditDetail: createAction('EMPLOYEES/CARD/CHART/UI/DETAIL_EDIT/OPEN')(),
    openShiftsUICreateDetail: createAction('EMPLOYEES/CARD/CHART/UI/DETAIL_CREATE/OPEN')(),
    openCreateShiftsFormBySelection: createAction('EMPLOYEES/CARD/CHART/UI/SHIFTS/CREATE/OPEN')<SelectionResultType>(),
    closeShiftsDetail: createAction('EMPLOYEES/CARD/CHART/UI/DETAIL/CLOSE')(),
};

const reducer = createReducer<EmployeeChartUIStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.setMovedInterval, (state, {payload}) => ({
        ...state,
        interval: {
            ...state.interval,
            move: payload,
        },
    }))
    .handleAction(actions.clearMovedInterval, state => ({
        ...state,
        interval: {},
        selectedShiftId: undefined,
    }))
    .handleAction(actions.openShiftsUIEditDetail, state => ({
        ...state,
        isDetailEditFormOpen: true,
    }))
    .handleAction(actions.openShiftsUICreateDetail, state => ({
        ...state,
        isDetailCreateFormOpen: true,
    }))
    .handleAction(actions.openCreateShiftsFormBySelection, (state, {payload}) => ({
        ...state,
        selectionData: payload,
        isDetailCreateFormOpen: true,
    }))
    .handleAction(actions.selectShift, (state, {payload}) => ({
        ...state,
        selectedShift: payload,
    }))
    .handleAction(actions.closeShiftsDetail, () => ({
        ...defaultState,
    }));

export default reducer;
