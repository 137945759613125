import moment from 'moment-timezone';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {localeIsReadySelector} from 'selectors/common/localization';
import {cookies, CookieName} from 'utils/cookies';

// Vite locale load work incorrect
// Workaround is: https://github.com/moment/moment/issues/5926#issuecomment-1056785251
import 'moment/dist/locale/ru';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/ka';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/az';
import 'moment/dist/locale/es';

import {Props} from './types';
import {Context} from './utils';

const Provider: Props = ({children}) => {
    const isReadyLocale = useSelector(localeIsReadySelector);
    const language = cookies.get(CookieName.Language);

    useEffect(() => {
        moment.locale(language);
    }, [language]);

    return (
        <Context.Provider value={{isReadyLocale}}>
            {children}
        </Context.Provider>
    );
};

export default Provider;
