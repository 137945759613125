import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {StateType} from './types';

export const defaultState: StateType = {
    isVisible: false,
    draft: undefined,
};

export const actions = {
    show: createAction('COMMON/ABSENCE_MODAL/SHOW')<StateType['draft']>(),
    hide: createAction('COMMON/ABSENCE_MODAL/HIDE')(),
};

const reducer = createReducer<StateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.show, (state, {payload}) => ({
        ...state,
        draft: payload,
        isVisible: true,
    }))
    .handleAction(actions.hide, state => ({
        ...state,
        draft: undefined,
        isVisible: false,
    }));

export default reducer;
