import {combineReducers} from 'redux';

import absences from './absences';
import applySchedules from './applySchedules';
import applyTags from './applyTags';
import attachSchedule from './attachSchedule';
import chart from './chart';
import detachSchedule from './detachSchedule';
import extraInfo from './loadExtraInfo';
import loadInfo from './loadInfo';
import modify from './modify';
import modifyExtraInfo from './modifyExtraInfo';
import schedule from './schedule';
import shifts from './shifts';
import tableBulk from './tableBulk';
import {EmployeeCardStateType} from './types';

const reducer = combineReducers<EmployeeCardStateType>({
    applySchedules,
    attachSchedule,
    detachSchedule,
    loadInfo,
    modify,
    absences,
    shifts,
    chart,
    extraInfo,
    modifyExtraInfo,
    applyTags,
    schedule,
    tableBulk,
});

export default reducer;
