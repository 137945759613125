import {combineReducers} from 'redux';

import {EmployeeItem} from 'types/api/uservices/api/employee';
import {generateEntityReducer} from 'utils/reducers/flow';

import {EmployeeExtraInfoStateType} from './types';

const PREFIX = 'COMMON_EMPLOYEES/CARD/EXTRA_INFO';

const {item} = generateEntityReducer<EmployeeItem>(PREFIX);

export const actions = item!.actions;

export default combineReducers<EmployeeExtraInfoStateType>({
    item: item!.reducer,
});
