import {combineReducers} from 'redux';

import inline, {actions as inlineActions} from './inline';

const containerReducer = combineReducers({
    inline,
});

export const actions = {
    inline: inlineActions,
};

export default containerReducer;
