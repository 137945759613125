import {Layout} from 'antd';
import styled from 'styled-components';

import {MENU_COLLAPSED_WIDTH, MENU_REVEALED_WIDTH} from 'components/Menu/Content/MenuTree/consts';

export const LayoutWrapper = styled(Layout)`
    min-height: 100vh;
`;

export const LayoutContent = styled(Layout.Content)<{isCollapsed: boolean}>`
    position: relative;
    margin: 16px 12px 0 12px;
    padding: 12px 8px 0 8px;
    transition: all .2s;

    ${({isCollapsed}) =>
        isCollapsed
            ? `margin-left: ${MENU_COLLAPSED_WIDTH + 12}px`
            : `margin-left: ${MENU_REVEALED_WIDTH + 12}px`
}
`;

export const LayoutBody = styled(Layout)`
    box-shadow: 0 0 0 1px rgba(0, 20, 51, 0.05), 0 2px 8px -4px rgba(0, 20, 51, 0.4);
    height: 100vh;
    overflow-y: auto;
`;
