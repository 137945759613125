import {createSelector} from 'reselect';

const baseSelector = (state: GlobalStateType) => state?.app?.config?.data?.configs;

export const versionSelector = createSelector(
    baseSelector,
    (state: GlobalStateType) => state.common.meta.item?.data?.version,
    (configs, version) => configs?.['wfm-frontend:version']?.version ?? version,
);

export const isApprovalsShiftsMultiselectEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:approvals-shifts-multiselect']?.enabled || false,
);

export const maxDraftsProcessingSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:maximum-drafts-processing']?.maxCount || 0,
);

export const isViolationSettingsEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:violation-settings']?.enabled || false,
);

export const isUpdateAddAbsenceFlowEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:update-add-absence-flow']?.enabled || false,
);

export const isForcastDiffValueEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:forecast-diff-value']?.enabled || false,
);

export const isShiftsPlanningChartsEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:shifts-planning-charts']?.enabled || false,
);

export const isAdditionalShiftDraftsEnabledSelector = createSelector(
    baseSelector,
    configs => configs?.['wfm-frontend:additional_shift_drafts']?.enabled || false,
);
