import {Typography} from 'antd';
import moment from 'moment-timezone';
import React, {memo} from 'react';

import SettingsApi from 'api/app/settings';

const CurrentTimezone = ({className}: ClassNameType) => {
    const currentTimezone = moment.tz(SettingsApi.getTimezone()).format('Z');

    return (
        <Typography.Text className={className}>
            {currentTimezone}
        </Typography.Text>
    );
};

export default memo(CurrentTimezone);
