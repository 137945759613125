// https://react-query.tanstack.com/guides/important-defaults

import azAZ from 'antd/lib/locale/az_AZ';
import enUS from 'antd/lib/locale/en_US';
import kkKZ from 'antd/lib/locale/kk_KZ';
import ruRU from 'antd/lib/locale/ru_RU';

const QUERY_CLIENT_DEFAULTS = {
    queries: {
        retry: 0,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    },
};

export const QUERY_CLIENT_CONFIG = {
    defaultOptions: QUERY_CLIENT_DEFAULTS,
};

export const ANTD_LOCALES = {
    ru: ruRU,
    en: enUS,
    az: azAZ,
    kz: kkKZ,
};
