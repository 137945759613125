import {combineReducers} from 'redux';

import {generateEntityReducer} from 'utils/reducers/flow';

import {StateType} from './types';

const PREFIX = 'COMMON/CREATE_CALENDAR_INFO_MODAL/ATTACH_SHIFT_SEGMENT';

const {item} = generateEntityReducer<StateType>(PREFIX);

export const actions = item!.actions;

export default combineReducers<StateType>({
    item: item!.reducer,
});
