import React, {memo} from 'react';

import Footer from './Footer';
import MenuTree from './MenuTree';
import {Wrapper} from './styles';
import {Props} from './types';

const Content: Props = ({isCollapsed}) => {
    return (
        <Wrapper>
            <MenuTree isCollapsed={isCollapsed}/>
            <Footer isCollapsed={isCollapsed}/>
        </Wrapper>
    );
};

export default memo(Content);
