import pick from 'lodash/pick';
import moment from 'moment-timezone';
import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {PaginationType, PeriodMomentType} from 'types/common';
import {ant2BackPagination} from 'utils/pagination';

import {AbsencesFiltersStateType} from './types';

const periodStart = moment().subtract(2, 'months')
    .startOf('month')
    .startOf('day');

const periodEnd = moment().add(2, 'months')
    .endOf('month')
    .endOf('day');

export const defaultState: AbsencesFiltersStateType = {
    period: [periodStart.clone(), periodEnd.clone()],
    oldFilters: {
        filterPeriod: [moment().subtract(2, 'week'), moment().add(2, 'week')],
        comment: undefined,
        absenceType: undefined,
    },
    isLastPage: false,
    ...ant2BackPagination(),
};

const PREFIX = 'COMMON_EMPLOYEES/CARD/ABSENCES_FILTERS';

export const actions = {
    // сразу old, потому что сейчас сразу выше новый календарь и там свои фильтры
    setOldFilters: createAction(`${PREFIX}/SET_OLD_FILTERS`)<Partial<AbsencesFiltersStateType['oldFilters']>>(),
    resetOldFilters: createAction(`${PREFIX}/RESET_OLD_FILTERS`)(),
    nextPage: createAction(`${PREFIX}/NEXT_PAGE`)(),
    prevPage: createAction(`${PREFIX}/PREV_PAGE`)(),
    setLastPage: createAction(`${PREFIX}/SET_LAST_PAGE`)<boolean>(),
    reset: createAction(`${PREFIX}/SET`)(),
    setPeriod: createAction(`${PREFIX}/SET_PERIOD`)<PeriodMomentType>(),
    resetPeriod: createAction(`${PREFIX}/RESET_PERIOD`)(),
};

function paginate(offset: PaginationType['offset'], limit: PaginationType['limit']) {
    const result = offset + limit;

    if (result <= 0) {
        return 0;
    }

    return result;
}

const reducer = createReducer<AbsencesFiltersStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.nextPage, state => ({
        ...state,
        offset: paginate(state.offset, state.limit),
    }))
    .handleAction(actions.setOldFilters, (state, {payload}) => ({
        ...state,
        oldFilters: {
            ...state.oldFilters,
            ...pick(payload, ['filterPeriod', 'comment', 'absenceType']),
        },
    }))
    .handleAction(actions.resetOldFilters, state => ({
        ...state,
        oldFilters: defaultState.oldFilters,
    }))
    .handleAction(actions.prevPage, state => ({
        ...state,
        offset: paginate(state.offset, -state.limit),
    }))
    .handleAction(actions.setLastPage, (state, {payload}: { payload: boolean }) => ({
        ...state,
        isLastPage: payload,
    }))
    .handleAction(actions.setPeriod, (state, action) => ({
        ...state,
        period: action.payload,
    }))
    .handleAction(actions.resetPeriod, state => ({
        ...state,
        period: [periodStart.clone(), periodEnd.clone()],
    }))
    .handleAction(actions.reset, () => defaultState);

export default reducer;
