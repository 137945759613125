import {Languages} from 'types/languages';
import {CookieName, cookies} from 'utils/cookies';
import i18n, {DEFAULT_LANGUAGE} from 'utils/localization/i18n';
import {TJSonTextTranslation} from 'utils/localization/types';

function setDefaultCookie() {
    cookies.set(CookieName.Language, DEFAULT_LANGUAGE);
    window.location.reload();
}

function prepareLanguage() {
    const currentLanguage = cookies.get(CookieName.Language);

    if (!currentLanguage) {
        setDefaultCookie();
        return undefined as never;
    }

    return currentLanguage;
}

export function preloader() {
    const currentLanguage = prepareLanguage();

    document.documentElement.lang = currentLanguage === Languages.Ru ? Languages.Ru : Languages.En;

    i18n.import(window.serverTranslations as any as TJSonTextTranslation, currentLanguage);
    delete window.serverTranslations;
}
