import {ActionType, createAction, createReducer} from 'typesafe-actions';

export type ModalsType = Record<string, {
    isOpened?: boolean;
    props?: object;
}>;

const baseDefaultState = {};

type OpenActionType<T extends object = object> = {
    key: string;
    props?: T;
};

export const modalsActions = {
    create: createAction('COMMON/MODALS/CREATE')<string>(),
    open: createAction('COMMON/MODALS/OPEN')<OpenActionType>(),
    close: createAction('COMMON/MODALS/CLOSE')<string>(),
};

export const modalsReducer = createReducer<ModalsType, ActionType<typeof modalsActions>>(baseDefaultState)
    .handleAction(
        [modalsActions.create, modalsActions.close],
        (state, action) => ({
            ...state,
            [action.payload]: {
                isOpened: false,
                props: undefined,
            },
        }),
    )
    .handleAction(
        modalsActions.open,
        (state, {payload}) => ({
            ...state,
            [payload.key]: {
                isOpened: true,
                props: payload.props,
            },
        }),
    );
