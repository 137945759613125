import {USER_URL} from 'api/user/consts';
import {UserDomainsInfoV2} from 'types/api/backend-py3/api/v2_idm_user_info';
import {EntityAPI} from 'types/http';
import {callCenterApi} from 'utils/httpApi';

class UsersAPI implements EntityAPI {
    public request = () => {
        return callCenterApi.get<UserDomainsInfoV2>(USER_URL);
    };
}

export const apiInstance = new UsersAPI();
