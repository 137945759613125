import '@yandex-int/rum-counter/dist/inline/interface';
import '@yandex-int/rum-counter/dist/inline/longtask';
import '@yandex-int/rum-counter/dist/bundle/scroll';
import '@yandex-int/rum-counter/dist/bundle/send';
import '@yandex-int/rum-counter/dist/bundle/inp';
import '@yandex-int/rum-counter/dist/bundle/onload';
import '@yandex-int/rum-counter/dist/bundle/retries';
import '@yandex-int/error-counter/dist/interfaceOverRum';
import '@yandex-int/error-counter/dist/implementation';
import '@yandex-int/error-counter/dist/filters';
import '@yandex-int/error-counter/dist/logError';
import '@yandex-int/error-counter/dist/logEvent';
import '@yandex-int/error-counter/dist/logAjaxError';

import {nanoid} from 'nanoid';

import {APP_EVENTS} from 'shared/consts';
import {cookies} from 'utils/cookies';

export type LogEventParams<T> = {value?: T; options?: Ya.Rum.LogEventOptions};

export class RumLogger {
    private static limits = {
        client: 20,
        uncaught: 20,
        script: 20,
        external: 20,
    };

    private static silent = {
        client: 10,
        uncaught: 10,
        script: 10,
        external: 10,
    };

    private static pingInterval: Nullable<number> = null;
    private static pingIntervalTime = 300_000;

    public static init = () => {
        Ya.Rum.init({
            beacon: true,
            clck: 'https://yandex.ru/clck/click',
            sendUrlInResTiming: true,
            sendAutoResTiming: true,
            reqid: cookies.get('Session_id'),
        }, {
            'rum_id': 'ru.taxi-frontend-wfm',
            '-project': 'taxi-frontend-wfm',
            '-page': window.location.pathname,
            '-platform': 'desktop',
            '-uid': cookies.get('Session_id') || '',
            '-version': APP_VERSION,
        });

        // @ts-expect-error import required
        import('@yandex-int/rum-counter/dist/bundle/implementation');

        Ya.Rum.initErrors({
            reqid: nanoid(),
            project: 'wfm',
            limits: RumLogger.limits,
            silent: RumLogger.silent,
        });
    };

    private static log = (error: Error, options: Ya.Rum.LogErrorOptions) => {
        Ya.Rum.logError({
            ...options,
            additional: {
                meta: {...options.additional},
            },
        }, error);
    };

    public static info = (error: Error, options?: Ya.Rum.LogErrorOptions) => RumLogger.log(error, {...options, level: 'info'});
    public static warn = (error: Error, options?: Ya.Rum.LogErrorOptions) => RumLogger.log(error, {...options, level: 'warn'});
    public static error = (error: Error, options?: Ya.Rum.LogErrorOptions) => RumLogger.log(error, {...options, level: 'error'});

    public static logEventString = (name: string, params?: LogEventParams<string>) => Ya.Rum.logEventString(name, params?.value ?? '', params?.options);
    public static logEventInteger = (name: string, params?: LogEventParams<number>) => Ya.Rum.logEventInteger(name, params?.value ?? 0, params?.options);
    public static logEventFloat = (name: string, params?: LogEventParams<number>) => Ya.Rum.logEventFloat(name, params?.value ?? 0, params?.options);

    public static updateErrors = (settings: Ya.Rum.LogErrorSettings) => Ya.Rum.updateErrors(settings);
    public static updateAdditional = (additional: Ya.Rum.Additional) => Ya.Rum.updateAdditional(additional);
    public static setVars = (vars: Ya.Rum.RumVars) => Ya.Rum.setVars(vars);

    public static logVersion = () => {
        if (RumLogger.pingInterval !== null) {
            clearInterval(RumLogger.pingInterval);
        }

        RumLogger.logEventString(APP_EVENTS.RUN);

        RumLogger.pingInterval = window.setInterval(
            () => RumLogger.logEventString(APP_EVENTS.RUN),
            RumLogger.pingIntervalTime,
        );
    };
}
