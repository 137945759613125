import {Popover, Typography} from 'antd';
import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {metaSelector} from 'selectors/common/meta';
import i18n from 'utils/localization';

import {RevealedItem, POPOVER_OVERLAY_STYLE} from '../styles';
import {ItemPropsType} from '../types';

import Content from './Content';

const content = <Content/>;

const AboutServiceWrapper: ItemPropsType = ({children, isCollapsed}) => {
    const meta = useSelector(metaSelector);

    return (
        <Popover
            overlayClassName="styled-menu-popover"
            trigger="click"
            placement="rightBottom"
            title={(
                <Typography.Text copyable={{text: JSON.stringify(meta)}}>{i18n.print('components.menu.information_about_the_system')}</Typography.Text>
            )}
            content={content}
            overlayStyle={POPOVER_OVERLAY_STYLE}
        >
            <RevealedItem isCollapsed={isCollapsed}>
                {children}
            </RevealedItem>
        </Popover>
    );
};

export default memo<ItemPropsType>(AboutServiceWrapper);
