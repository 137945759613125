import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {OperatorsScheduleTypeDeleteRequest} from 'types/api/backend-py3/api/v1_operators_schedule_type_modify';

import {DetachScheduleFromEmployeeStateType} from './types';

export const defaultState: DetachScheduleFromEmployeeStateType = {
    data: undefined,
    status: RequestStatusInitial,
    desc: null,
};

export const actions = {
    requestDetachSchedules: createAction('COMMON_EMPLOYEES/CARD/SCHEDULES/DETACH/REQUEST')<OperatorsScheduleTypeDeleteRequest>(),
    successDetachSchedules: createAction('COMMON_EMPLOYEES/CARD/SCHEDULES/DETACH/SUCCESS')(),
    failDetachSchedules: createAction('COMMON_EMPLOYEES/CARD/SCHEDULES/DETACH/FAIL')<string>(),
};

const reducer = createReducer<DetachScheduleFromEmployeeStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.requestDetachSchedules, state => ({
        ...state,
        status: RequestStatusPending,
        desc: null,
    }))
    .handleAction(actions.successDetachSchedules, state => ({
        ...state,
        status: RequestStatusSuccessful,
        desc: null,
    }))
    .handleAction(actions.failDetachSchedules, (state: DetachScheduleFromEmployeeStateType, action: {payload: string}) => ({
        ...state,
        status: RequestStatusFailed,
        desc: action.payload,
    }));

export default reducer;
