import moment from 'moment-timezone';
import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {PeriodPickerValueType} from 'types/common';

import {StateType} from './types';

export const defaultState: StateType = [
    moment().startOf('week'), moment().endOf('week'),
];

export const actions = {
    set: createAction('EMPLOYEES/CARD/CHART/PERIOD_FILTER/SET')<PeriodPickerValueType>(),
    reset: createAction('EMPLOYEES/CARD/CHART/PERIOD_FILTER/RESET')(),
};

const reducer = createReducer<StateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.set, (_, {payload}) => payload)
    .handleAction(actions.reset, () => null);

export default reducer;
