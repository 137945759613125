import React, {memo} from 'react';

import DomainSelect from './DomainSelect';
import LanguageSelect from './LanguageSelect';
import {SettingsFormWrapper} from './styles';
import TimezoneSelect from './TimezoneSelect';
import {Props} from './types';

const Content: Props = () => {
    return (
        <SettingsFormWrapper>
            <TimezoneSelect/>
            <DomainSelect/>
            <LanguageSelect/>
        </SettingsFormWrapper>
    );
};

export default memo(Content);
