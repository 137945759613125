import React from 'react';
import {Route} from 'react-router-dom';

import withSuspense from 'hocs/withSuspense';

import {SECTION_PATHS} from './consts';

const Schedules = withSuspense(
    React.lazy(() => import('./components/Page')),
);

export default (
    <React.Fragment>
        <Route path={SECTION_PATHS.CREATE} exact>
            <Schedules createMode/>
        </Route>
        <Route path={SECTION_PATHS.COPY} exact>
            <Schedules createMode/>
        </Route>
        <Route path={SECTION_PATHS.INDEX} component={Schedules} exact/>
    </React.Fragment>
);
