import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {OperatorsShiftModifyRequest} from 'types/api/backend-py3/api/v1_operators_shift_modify';

import {SaveInlineShiftsResponseType, SaveInlineShiftsStateType} from './types';

export const defaultState: SaveInlineShiftsStateType = {
    data: null,
    status: RequestStatusInitial,
    desc: null,
};

export const actions = {
    requestSaveShifts: createAction('COMMON/EMPLOYEES/CARD/CHART/SHIFTS/SAVE/INLINE/REQUEST')<OperatorsShiftModifyRequest>(),
    successSaveShifts: createAction('COMMON/EMPLOYEES/CARD/CHART/SHIFTS/SAVE/INLINE/SUCCESS')<SaveInlineShiftsResponseType>(),
    failSaveShifts: createAction('COMMON/EMPLOYEES/CARD/CHART/SHIFTS/SAVE/INLINE/FAIL')<string>(),
};

const reducer = createReducer<SaveInlineShiftsStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.requestSaveShifts, state => ({
        ...state,
        status: RequestStatusPending,
        desc: null,
        data: null,
    }))
    .handleAction(actions.successSaveShifts, (state, action) => ({
        ...state,
        status: RequestStatusSuccessful,
        desc: null,
        data: action.payload,
    }))
    .handleAction(actions.failSaveShifts, (state: SaveInlineShiftsStateType, action) => ({
        ...state,
        status: RequestStatusFailed,
        desc: action.payload,
        data: null,
    }));

export default reducer;
