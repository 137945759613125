export interface CropTextArgs {
    /*
    * максимальная длиная текста на выходе без учёта «...»
    * по умолчанию стоит DEFAULT_MAX_LENGTH
    * */
    maxLength?: number;
    /*
    * с какого индекса резать текст
    * по умолчанию стоит DEFAULT_FROM
    * */
    from?: number;
    /*
    * задаётся конец строки
    * по умолчанию ...
    * */
    endOfString?: string;
}

const DEFAULT_FROM = 0;
/*
* сразу для небольших областей резать по умолчанию
* для иных задач, задавать явно параметр
* */
const DEFAULT_MAX_LENGTH = 10;
const END_OF_STRING = '...';

export function cropText(text: string, args?: CropTextArgs) {
    const {
        maxLength = DEFAULT_MAX_LENGTH,
        from = DEFAULT_FROM,
        endOfString = END_OF_STRING,
    } = args || {};

    if (text.length <= maxLength) {
        return text;
    }

    if (text.length <= endOfString.length) {
        return text;
    }

    return `${text.slice(from, maxLength)}${endOfString}`;
}
