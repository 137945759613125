import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {UIStateType} from './types';

export const defaultState: UIStateType = {
    modalPAVisible: false,
};

export const actions = {
    toggleModalPA: createAction('COMMON/SHIFTS_MULTISELECT/UI/TOGGLE_MODAL_PA')(),
};

const reducer = createReducer<UIStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.toggleModalPA, state => ({
        modalPAVisible: !state.modalPAVisible,
    }));

export default reducer;
