import axios, {InternalAxiosRequestConfig} from 'axios';

import {ErrorResponse} from 'types/api/backend-py3/definitions';

type BaseError = {
    message: string;
    name?: string;
    stack?: string;
}

interface NetworkError extends BaseError {
    response?: ErrorResponse;
    details?: ErrorResponse['details'];
    status?: number;
    code?: string;
    config?: InternalAxiosRequestConfig;
}

type ErrorMessage = BaseError & NetworkError;

export function getErrorMessage(exception: unknown): ErrorMessage {
    if (axios.isAxiosError(exception)) {
        return {
            message: exception.response?.data?.message || exception.message,
            response: exception.response?.data,
            details: exception.response?.data.details,
            name: exception.name,
            stack: exception.stack,
            status: exception.response?.status,
            code: exception.response?.data.code,
            config: exception.config,
        };
    }

    if (exception instanceof Error) {
        return {message: exception.message, name: exception.name, stack: exception.stack};
    }

    return {message: JSON.stringify(exception)};
}
