import {Typography} from 'antd';
import styled from 'styled-components';

import {colors} from 'utils/styles/colors';

export const ServiceInfo = styled(Typography.Text)<{isCollapsed: boolean}>`
    display: flex;
    background-color: ${colors.lightBlue};
    height: 36px;
    border-radius: 6px;
    transition: all ease 0.2s;

    ${props => props.isCollapsed
        ? `
            width: 100%;
            height: 36px;
            flex-direction: column;
            padding: 0 4px;

            .ant-typography {
                font-size: 11px;
                line-height: 16px;
            }
        `
        : `
            max-width: 100%;
            height: 28px;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: auto;
            padding: 0 8px;

            .ant-typography {
                font-size: 12px;
                line-height: 20px;
            }
        `}
`;

export const MetaWrapper = styled.div<{isCollapsed: boolean}>`
    display: flex;
    align-items: center;
    box-shadow: inset 0 1px 0 0 ${colors.dirtyWhite};
    transition: padding ease 0.2s;
    width: 100%;

    ${props => props.isCollapsed
        ? `
            padding: 12px 4px;
        `
        : `
            padding: 12px 16px;
        `}
`;
