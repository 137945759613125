import {Row, Col, Modal} from 'antd';
import React, {FC, memo} from 'react';
import {useSelector} from 'react-redux';

import TimezonesPicker from 'components/Controls/TimezonesPicker';
import {bound as actions} from 'reducers/app/actions';
import {TimezoneType} from 'reducers/app/types';
import appTimezoneSelector from 'selectors/app/settings/appTimezoneSelector';
import i18n from 'utils/localization';
import {FULL_WIDTH_STYLE, ROW_GUTTER} from 'utils/styles';

function handleOkChangeTimezone(timezone: string) {
    actions.setTimezone(timezone);
}

function handleChangeTimezone(timezone: TimezoneType) {
    Modal.confirm({
        title: i18n.print('common.page_will_be_reloaded'),
        content: `${i18n.print('common.continue')}?`,
        okText: i18n.print('common.yes'),
        cancelText: i18n.print('common.no'),
        okType: 'danger',
        onOk: () => {
            handleOkChangeTimezone(timezone);
        },
    });
}

const TimezoneSelect: FC = () => {
    const timezone = useSelector(appTimezoneSelector);

    return (
        <Row gutter={ROW_GUTTER} align="middle">
            <Col span={8}>
                {i18n.print('common.timezone')}
            </Col>
            <Col span={16}>
                <TimezonesPicker
                    value={timezone}
                    onChange={handleChangeTimezone}
                    style={FULL_WIDTH_STYLE}
                />
            </Col>
        </Row>
    );
};

export default memo(TimezoneSelect);
