import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ConfigProvider, App} from 'antd';
import {default as ru} from 'antd/lib/locale/ru_RU';
import React, {memo, useState, useEffect} from 'react';
import {Router} from 'react-router-dom';

import {history} from 'reduxStore';
import {cookies, CookieName} from 'utils/cookies';
import {LocalizationProvider} from 'utils/localization';

import {QUERY_CLIENT_CONFIG, ANTD_LOCALES} from '../consts';
import {theme} from '../theme';

export const queryClient = new QueryClient(QUERY_CLIENT_CONFIG);
const language = cookies.get(CookieName.Language);

export default memo(({children}: {children: React.ReactNode}) => {
    const [antdLocale, setAntdLocale] = useState(ru);

    useEffect(() => {
        setAntdLocale(ANTD_LOCALES[language as keyof typeof ANTD_LOCALES]);
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <ConfigProvider locale={antdLocale} theme={theme}>
                <App>
                    <Router history={history}>
                        <LocalizationProvider>
                            {children}
                        </LocalizationProvider>
                    </Router>
                </App>
            </ConfigProvider>
        </QueryClientProvider>
    );
});
