import {FC} from 'react';

export const enum EventType {
    Absence = 'absence',
    Break = 'break',
    Violation = 'violation',
    ProjectActivity = 'project-activity',
    NewShiftSegment = 'new-shift-segment',
}

export type EventTypeSelectOption = {
    value: EventType;
    label: string;
    disabled?: boolean;
};

type OwnProps = {
    withNewShiftSegment?: boolean;
    disabled?: boolean;
}

export type Props = FC<OwnProps>;
