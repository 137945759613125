import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {RemoveShiftStateType, SuccessRemoveType} from './types';

export const defaultState: RemoveShiftStateType = {
    data: null,
    status: RequestStatusInitial,
    desc: null,
};

export const actions = {
    requestRemoveShift: createAction('COMMON/SHIFT_SIDEBAR/FORMS/SHIFT/REMOVE/REQUEST')(),
    successRemoveShift: createAction('COMMON/SHIFT_SIDEBAR/FORMS/SHIFT/REMOVE/SUCCESS')<SuccessRemoveType>(),
    failRemoveShift: createAction('COMMON/SHIFT_SIDEBAR/FORMS/SHIFT/REMOVE/FAIL')<string>(),
};

const reducer = createReducer<RemoveShiftStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.requestRemoveShift, state => ({
        ...state,
        status: RequestStatusPending,
        desc: null,
        data: null,
    }))
    .handleAction(actions.successRemoveShift, (state, action: {payload: SuccessRemoveType}) => ({
        ...state,
        status: RequestStatusSuccessful,
        desc: null,
        data: action.payload,
    }))
    .handleAction(actions.failRemoveShift, (state: RemoveShiftStateType, action: {payload: string}) => ({
        ...state,
        status: RequestStatusFailed,
        desc: action.payload,
        data: null,
    }));

export default reducer;
