import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {PFDTableLoadStatsStateType, PFDTableLoadStatsResponseType} from './types';

export const defaultState: PFDTableLoadStatsStateType = {
    params: null,
    data: null,
    status: RequestStatusInitial,
    desc: null,
};

const PREFIX = 'SHIFT_PLANNING/TABLE/STATS/LOAD';

export const actions = {
    requestStats: createAction(`${PREFIX}/REQUEST`)(),
    loadMoreStats: createAction(`${PREFIX}/LOAD_MORE`)(),
    successStats: createAction(`${PREFIX}/SUCCESS`)<PFDTableLoadStatsResponseType>(),
    resetStats: createAction(`${PREFIX}/RESET`)(),
    failStats: createAction(`${PREFIX}/FAIL`)<string>(),
};

export const reducer = createReducer<PFDTableLoadStatsStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.requestStats, state => ({
        ...state,
        status: RequestStatusPending,
        desc: null,
        data: null,
    }))
    .handleAction(actions.loadMoreStats, state => ({
        ...state,
        status: RequestStatusPending,
        desc: null,
    }))
    .handleAction(actions.successStats, (state, action) => ({
        ...state,
        status: RequestStatusSuccessful,
        desc: null,
        data: action.payload,
    }))
    .handleAction(actions.failStats, (state: PFDTableLoadStatsStateType, action) => ({
        ...state,
        status: RequestStatusFailed,
        desc: action.payload,
        data: null,
    }))
    .handleAction(actions.resetStats, state => ({
        ...state,
        status: RequestStatusInitial,
        desc: null,
        data: null,
    }));
