import React, {lazy} from 'react';
import {Route} from 'react-router-dom';

import withSuspense from 'hocs/withSuspense';
import {PATHS} from 'routes/consts';

const AsyncAccrualsPage = withSuspense(
    lazy(() => import('./ui/accruals-page')),
);

export default (
    <React.Fragment>
        <Route path={PATHS.APPROVALS.ACCRUALS} component={AsyncAccrualsPage}/>
    </React.Fragment>
);
