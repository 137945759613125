import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {EmployeesStateType, CheckedRowType} from './types';

export const defaultState: EmployeesStateType = {
    checkedRows: [],
};

export const actions = {
    set: createAction('COMMON/SHIFTS_MULTISELECT/EMPLOYEES/SET')<CheckedRowType[]>(),
    reset: createAction('COMMON/SHIFTS_MULTISELECT/EMPLOYEES/RESET')(),
    check: createAction('COMMON/SHIFTS_MULTISELECT/EMPLOYEES/CHECK')<CheckedRowType>(),
    uncheck: createAction('COMMON/SHIFTS_MULTISELECT/EMPLOYEES/UNCHECK')<CheckedRowType>(),
};

const toggleCheck = (rows: CheckedRowType[], item: CheckedRowType, isChecked: boolean) => {
    return rows.map(row => {
        if (item.indexedYandexUid === row.indexedYandexUid) {
            return {
                ...row,
                yandexUid: row.yandexUid,
                checked: isChecked,
            };
        } else {
            return row;
        }
    });
};

export const reducer = createReducer<EmployeesStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.set, (state, {payload}) => {
        const result = payload.reduce<CheckedRowType[]>((acc, current) => {
            const item = state.checkedRows.find(row => {
                return row.yandexUid === current.yandexUid && row.date.isSame(current.date, 'date');
            });

            if (item) {
                acc.push({
                    ...item,
                    indexedYandexUid: current.indexedYandexUid,
                });
            } else {
                acc.push({
                    ...current,
                    checked: true,
                });
            }

            return acc;
        }, []);

        return {
            checkedRows: result,
        };
    })
    .handleAction(actions.check, (state, {payload}) => {
        const newRows = toggleCheck(state.checkedRows, payload, true);

        return {
            checkedRows: newRows,
        };
    })
    .handleAction(actions.uncheck, (state, {payload}) => {
        const newRows = toggleCheck(state.checkedRows, payload, false);

        return {
            checkedRows: newRows,
        };
    })
    .handleAction(actions.reset, () => {
        return defaultState;
    });
