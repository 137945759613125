import {combineReducers} from 'redux';

import focusElement from './focusElement';
import sidebarVisibility from './sidebarVisibility';
import {ProjectActivitiesStateType} from './types';

const containerReducer = combineReducers<ProjectActivitiesStateType>({
    sidebarVisibility,
    focusElement,
});

export default containerReducer;
