import {Moment} from 'moment-timezone';
import {RangeValueType} from 'rc-picker/es/PickerInput/RangePicker';

export type PeriodPickerValueType = RangeValueType<Moment>;

export type SkillValueType = string;

export type PlainPeriodType = [string | undefined, string | undefined];

export type PaginationType = {
    limit: number;
    offset: number;
}

export type PeriodMomentType = Undefinable<[Undefinable<Moment>, Undefinable<Moment>]>;

export const enum Modes {
    Create = 'create',
    Edit = 'edit',
    Copy = 'copy',
    Draft = 'draft'
}
