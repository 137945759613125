import {stubFalse} from 'lodash';

import {BindSagaConfig} from './types';

const noop = (): null => null;

export const DEFAULTS: BindSagaConfig<unknown, null> = {
    checkReload: stubFalse,
    mapOwnPropsToSaga: noop,
};
