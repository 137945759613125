import {combineReducers} from 'redux';

import remove from './remove';
import setup from './setup';
import {ShiftsTabStateType} from './types';

export default combineReducers<ShiftsTabStateType>({
    remove,
    setup,
});
