import {Popover} from 'antd';
import React, {memo} from 'react';

import {POPOVER_OVERLAY_STYLE} from '../Settings/FooterMenu/styles';

import Content from './Content';
import Info from './Info';
import {AccountWrapper} from './styles';
import {Props} from './types';

const content = <Content/>;

const Account: Props = ({isCollapsed}) => {
    return (
        <Popover
            overlayClassName="styled-menu-popover account-profile-popover"
            trigger="click"
            placement="rightBottom"
            content={content}
            overlayStyle={POPOVER_OVERLAY_STYLE}
        >
            <AccountWrapper>
                <Info isCollapsed={isCollapsed}/>
            </AccountWrapper>
        </Popover>
    );
};

export default memo<Props>(Account);
