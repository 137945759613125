import {Typography} from 'antd';
import React, {memo} from 'react';

import CurrentDomain from './CurrentDomain';
import CurrentLanguage from './CurrentLanguage';
import CurrentTimezone from './CurrentTimezone';
import {ServiceInfo, MetaWrapper} from './styles';
import {Props} from './types';

const MAX_LENGTH_COLLAPSED_DOMAIN = 7;
const MAX_LENGTH_DOMAIN = 16;

const Meta: Props = ({isCollapsed}) => {
    return (
        <MetaWrapper isCollapsed={isCollapsed}>
            <ServiceInfo isCollapsed={isCollapsed}>
                <CurrentDomain
                    maxLength={isCollapsed ? MAX_LENGTH_COLLAPSED_DOMAIN : MAX_LENGTH_DOMAIN}
                />
                {isCollapsed
                    ? (
                        <Typography.Text>
                            (<CurrentTimezone/>)
                        </Typography.Text>
                    )
                    : (
                        <Typography.Text>
                            &nbsp;(<CurrentTimezone/> / <CurrentLanguage/>)
                        </Typography.Text>
                    )
                }
            </ServiceInfo>
        </MetaWrapper>
    );
};

export default memo(Meta);
