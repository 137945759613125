import {omit} from 'lodash';
import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {StoredFiltersStateType} from './types';

export const defaultState: StoredFiltersStateType = {};

export const actions = {
    set: createAction('COMMON/STORED_FILTERS/SET')<StoredFiltersStateType>(),
    remove: createAction('COMMON/STORED_FILTERS/REMOVE')<string[]>(),
    reset: createAction('COMMON/STORED_FILTERS/RESET')(),
};

const reducer = createReducer<StoredFiltersStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.set, (state, action) => ({
        ...state,
        ...action.payload,
    }))
    .handleAction(actions.remove, (state, action) => omit(state, action.payload))
    .handleAction(actions.reset, () => defaultState);

export default reducer;
