import {createSelector} from 'reselect';

const yandexTeamDomain = 'yandex-team.ru';

export default createSelector(
    (state: GlobalStateType) => state.app.config.data?.passportHost,
    (state: GlobalStateType) => state.app.config.data?.yandexTeamPassportHost,
    (passportHost, yandexTeamPassportHost) => {
        if (window.location.host.includes(yandexTeamDomain)) {
            return {
                host: yandexTeamPassportHost,
                type: 'internal',
            };
        }

        return {
            host: passportHost,
            type: 'external',
        };
    },
);
