import {ArrowRightOutlined, ArrowLeftOutlined} from '@ant-design/icons';
import {Layout} from 'antd';
import React, {useEffect} from 'react';
import styled from 'styled-components';

import {CookieName, cookies} from 'utils/cookies';
import {DEFAULT_LANGUAGE} from 'utils/localization/consts';
import {useLanguagesQuery} from 'utils/queries/languages/useLanguagesQuery';
import {colors} from 'utils/styles/colors';

import Content from './Content';
import {MENU_COLLAPSED_WIDTH, MENU_REVEALED_WIDTH} from './Content/MenuTree/consts';
import Logo from './Logo';
import {Props} from './types';

const language = cookies.get(CookieName.Language);

const Menu: Props = ({className, isCollapsed, toggleMenu}) => {
    const {data: languages, isSuccess} = useLanguagesQuery();

    useEffect(() => {
        const allowedLanguage = languages?.find(lang => lang.lang === language);

        if (!allowedLanguage && isSuccess) {
            cookies.set(CookieName.Language, DEFAULT_LANGUAGE);
            window.location.reload();
        }
    }, [languages, isSuccess]);

    return (
        <Layout.Sider
            className={className}
            width={MENU_REVEALED_WIDTH}
            collapsedWidth={MENU_COLLAPSED_WIDTH}
            collapsible
            theme="light"
            collapsed={isCollapsed}
            onCollapse={toggleMenu}
            defaultCollapsed
            trigger={isCollapsed ? <ArrowRightOutlined/> : <ArrowLeftOutlined/>}
        >
            <Logo isCollapsed={isCollapsed}/>
            <Content isCollapsed={isCollapsed}/>
        </Layout.Sider>
    );
};

export default styled(Menu)`
    && {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        height: 100vh;
        overflow: hidden;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }

    .ant-layout-sider-trigger {
        box-shadow: inset 0 1px 0 0 ${colors.dirtyWhite};
    }
`;
