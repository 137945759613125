import cookies from 'js-cookie';

import {Domains} from 'types/domains';

export const enum CookieName {
    Language = 'language',
    Domain = 'domain',
}

export function setNewDomain(newDomain?: Domains) {
    if (newDomain) {
        cookies.set(CookieName.Domain, newDomain);
        return;
    }

    const cookieDomain = cookies.get(CookieName.Domain);

    if (!cookieDomain || (!!newDomain && cookieDomain !== newDomain)) {
        cookies.set(CookieName.Domain, newDomain || Domains.Taxi);

        if (!cookieDomain) {
            // перезапрашиваем страницу с правильными переводами
            window.location.reload();
        }
    }
}

export {cookies};
