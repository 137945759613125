import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {MetrikaStateType} from './types';

export const defaultState: MetrikaStateType = {
    timepicker: {
        from: null,
    },
};

export const actions = {
    timepickerSetSource: createAction('COMMON/METRIKA/TIMEPICKER_SET_SOURCE')<MetrikaStateType['timepicker']['from']>(),
    timepickerResetSource: createAction('COMMON/METRIKA/TIMEPICKER_RESET_SOURCE')(),
};

const reducer = createReducer<MetrikaStateType, ActionType<typeof actions>>(defaultState)
    .handleAction(actions.timepickerSetSource, (state, action) => ({
        timepicker: {
            from: action.payload,
        },
    }))
    .handleAction(actions.timepickerResetSource, () => ({
        timepicker: {
            from: null,
        },
    }));

export default reducer;
