import React, {memo} from 'react';

import Account from './Account';
import Meta from './Meta';
import Settings from './Settings';
import {InfoWrapper} from './styles';
import {Props} from './types';

const Footer: Props = ({className, isCollapsed}) => {
    return (
        <InfoWrapper className={className}>
            <Meta isCollapsed={isCollapsed}/>
            <Settings isCollapsed={isCollapsed}/>
            <Account isCollapsed={isCollapsed}/>
        </InfoWrapper>
    );
};

export default memo(Footer);
