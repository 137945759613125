import {combineReducers} from 'redux';

import {RemoveShiftItemType} from 'api/shifts/types';
import {generateEntityReducer, generateSelectors} from 'utils/reducers/flow';

import {RemoveShiftsStateType} from './types';

const PREFIX = 'COMMON_EMPLOYEES/CARD/SHIFTS/REMOVE';

const {item} = generateEntityReducer<null, object, {data: Nullable<RemoveShiftItemType[]>}>(PREFIX);

export const selectors = generateSelectors<GlobalStateType, null>(
    s => s.common.employees.card.shifts.remove,
);

export const actions = item!.actions;

export default combineReducers<RemoveShiftsStateType>({
    item: item!.reducer,
});
