import moment from 'moment-timezone';

import {Languages} from 'types/languages';
import {CookieName, cookies} from 'utils/cookies';

export function preloader() {
    const currentLanguage = cookies.get(CookieName.Language);

    if (currentLanguage === Languages.En) {
        moment.updateLocale('en', {
            week: {
                dow: 1,
            },
        });
        moment.updateLocale('en-gb', {
            week: {
                dow: 1,
            },
        });
    }

    if (currentLanguage === Languages.Az) {
        const weekdays = moment.localeData('az').weekdaysMin();

        moment.updateLocale('az', {
            weekdaysShort: weekdays,
        });
    }
}
