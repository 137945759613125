import './init';

import React from 'react';
import ReactDOMClient from 'react-dom/client';

import App from 'components/App';
import {initializeYupLocale} from 'shared/consts/yup';
import {syncAppPreloader} from 'utils/appPreloader';
import {RumLogger} from 'utils/rumLogger';

import 'antd/dist/reset.css';
import '@fullcalendar/react/dist/vdom';

syncAppPreloader();
RumLogger.init();
initializeYupLocale();

const container = document.getElementById('application');

if (container) {
    const root = ReactDOMClient.createRoot(container);

    root.render(<App/>);
} else {
    RumLogger.error(new Error('Application container not found!'), {
        message: 'Application container not found!',
        block: 'App',
        source: 'index',
        method: 'createRoot',
    });
}
