import React from 'react';
import {Saga} from 'redux-saga';
import {v4 as uuid} from 'uuid';

import {DEFAULTS} from './consts';
import {BindSagaConfig, ExtendedProps} from './types';
import {runFork, getDisplayName, loadTask, disposeTask} from './utils';

export function withSaga<P extends object, M = any>(
    effect: Saga,
    options?: BindSagaConfig<P, M>,
): (Component: React.ComponentType<ExtendedProps<P>>) =>
    React.ComponentType<ExtendedProps<P>> {
    return (Component: React.ComponentType<ExtendedProps<P>>) => {
        let sagaInstance: Saga;
        let isForked = false;

        const {checkReload, mapOwnPropsToSaga} = {...(DEFAULTS as any as BindSagaConfig<P, M>), ...options};

        class WithSaga extends React.PureComponent<P> {
            public static displayName = `SagaBind(${getDisplayName(Component)})`;

            public static defaultProps = Component.defaultProps;

            private loadId: string;

            constructor(props: P) {
                super(props);

                if (!isForked) {
                    sagaInstance = runFork(effect);
                    isForked = true;
                }

                // TODO: https://st.yandex-team.ru/TEWFM-1414#64187025767501619c68246b
                // @ts-expect-error _loadId не должен менятся при HMR, иначе получим неверный id для asyncOperations
                if (!this.loadId) {
                    this.loadId = `${WithSaga.displayName} ${options?.id || uuid()}`;
                }
            }

            public _loadFlow = () => {
                loadTask({
                    saga: sagaInstance,
                    loadId: this.loadId,
                    args: mapOwnPropsToSaga?.(this.props),
                    isRoot: options?.isRoot,
                });
            };

            public _disposeFlow = () => {
                disposeTask(this.loadId);
            };

            // Do not use in lifecycle methods of child components, it can be unsafe.
            // Do not use it in complicated flows because of the same reason.
            // Use it in event handlers (ie for onClick handler of "Retry" buttons after page load errors)
            public reload = () => {
                this._disposeFlow();
                this._loadFlow();
            };

            public componentDidMount() {
                this._loadFlow();
            }

            public componentDidUpdate(prevProps: P) {
                if (checkReload?.(prevProps, this.props)) {
                    this.reload();
                }
            }

            public componentWillUnmount() {
                this._disposeFlow();
            }

            public render() {
                return (
                    <Component
                        loadId={this.loadId}
                        reload={this.reload}
                        {...this.props}
                    />
                );
            }
        }

        return WithSaga;
    };
}
