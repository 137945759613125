import {isEmpty} from 'lodash';
import {createSelector} from 'reselect';

import {domainsSelectors} from 'reducers/resources/domains';
import {userDomainsSelector} from 'selectors/app/user';

export const domainsSelector = createSelector(
    domainsSelectors.itemSelector,
    domains => domains || [],
);

export const domainsOptionsSelector = createSelector(
    userDomainsSelector,
    domainsSelector,
    (userDomains, defaultDomains) => {
        return isEmpty(userDomains)
            ? defaultDomains
            : defaultDomains.filter(domain => userDomains.includes(domain.value));
    },
);
