import React, {FC} from 'react';

import YandexMetrika from './YandexMetrika';

const Metrics: FC = () => {
    return (
        <>
            <YandexMetrika/>
        </>
    );
};

export default Metrics;
