import {combineReducers} from 'redux';

import attach from './attach';
import detach from './detach';
import filters from './filters';
import list from './list';

const reducer = combineReducers({
    filters,
    attach,
    detach,
    list,
});

export default reducer;
