import React from 'react';
import {Route} from 'react-router-dom';

import withSuspense from 'hocs/withSuspense';
import {PATHS} from 'routes/consts';

const LazyPageLoadable = withSuspense(
    React.lazy(() => import('./components/Page')),
);

export default (
    <React.Fragment>
        <Route path={PATHS.ABSENCES} component={LazyPageLoadable}/>
    </React.Fragment>
);
