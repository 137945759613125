export function isString(value: unknown): value is string {
    return typeof value === 'string';
}

export function isEmptyString(value: unknown): value is string {
    return isString(value) && value.length === 0;
}

export function isNotEmptyString(value: unknown): value is string {
    return isString(value) && value.length > 0;
}

export function isNumber(value: unknown): value is number {
    return typeof value === 'number';
}

export function isBoolean(value: unknown): value is boolean {
    return typeof value === 'boolean';
}

export function isArray<T = unknown>(value: unknown): value is T[] {
    return Array.isArray(value);
}

export function isObject<T = Record<string, unknown>>(value: unknown): value is T {
    return typeof value === 'object' && !value !== null && !isArray(value);
}

export function isEmptyObject<T = Record<string, unknown>>(value: unknown): value is T {
    return isObject(value) && Object.keys(value).length === 0;
}
