import React, {memo} from 'react';

import FooterMenu from './FooterMenu';
import {SettingsWrapper} from './styles';
import {Props} from './types';

const Settings: Props = ({className, isCollapsed}) => {
    return (
        <SettingsWrapper className={className}>
            <FooterMenu isCollapsed={isCollapsed}/>
        </SettingsWrapper>
    );
};

export default memo(Settings);
