import React from 'react';
import {Provider as StoreProvider} from 'react-redux';

import store from 'reduxStore';

import Common, {queryClient} from './Common';

export {queryClient};
export default ({children}: {children: React.ReactNode}) => {
    return (
        <StoreProvider store={store}>
            <Common>
                {children}
            </Common>
        </StoreProvider>
    );
};
