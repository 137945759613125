import {JobSetupStatusResponse} from 'types/api/backend-py3/api/v1_job_setup_status';
import {generateEntityReducer} from 'utils/reducers/flow';

const PREFIX = 'COMMON/CARD/SHIFTS/SETUP/TASK/POLL';

const {item} = generateEntityReducer<JobSetupStatusResponse>(PREFIX);

export const taskPollActions = item!.actions;

export default item!.reducer;
