import {Immutable} from 'immer';
import {cloneDeep} from 'lodash';
import get from 'lodash/get';
import {createSelector} from 'reselect';

import {makeModel} from 'utils/model';

import {BaseEntityState, StatePathSelectorFn} from '../types';

import {EntitySelectors} from './types';

export function createEntitySelectors<TData>(statePath: StatePathSelectorFn<BaseEntityState<TData>>): EntitySelectors<TData> {
    const path = makeModel(statePath);

    const dataPath = `${path}.data`;
    const statusPath = `${path}.status`;
    const errorPath = `${path}.error`;

    return {
        itemSelector: createSelector(
            (state: GlobalStateType) => get(state, dataPath),
            (value: Immutable<TData>) => cloneDeep(value as TData),
        ),
        statusSelector: createSelector(
            (state: GlobalStateType) => get(state, statusPath),
            (status: RequestStatusType) => status,
        ),
        isPendingSelector: createSelector(
            (state: GlobalStateType) => get(state, statusPath),
            (status: RequestStatusType) => status === RequestStatusPending,
        ),
        errorSelector: createSelector(
            (state: GlobalStateType) => get(state, errorPath),
            (error: string) => error,
        ),
    };
}
