import {combineReducers} from 'redux';

import {generateEntityReducer} from 'utils/reducers/flow';

import {StateType, MetaData} from './types';

const PREFIX = 'COMMON/META';

const {item} = generateEntityReducer<MetaData>(PREFIX);

export const actions = item!.actions;

export default combineReducers<StateType>({
    item: item!.reducer,
});
