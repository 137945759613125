import React from 'react';
import styled from 'styled-components';

import img from './broken-bicycle.svg';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export default () => (
    <Wrapper>
        <div style={{maxWidth: '50%'}}>
            <h1 style={{textAlign: 'center', fontSize: '32px'}}>Error</h1>
            <img src={img}/>
        </div>
    </Wrapper>
);
