import {VersionResponse} from 'api/version/types';
import {nodeApi} from 'utils/httpApi';

class Version {
    public request = () => {
        return nodeApi.get<VersionResponse>('/version');
    };
}

export const apiInstance = new Version();
