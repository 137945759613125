import React from 'react';
import {Route} from 'react-router-dom';

import withSuspense from 'hocs/withSuspense';

import {SECTION_PATHS} from './consts';

const Nanimator = withSuspense(
    React.lazy(() => import('./components/Page')),
);

export default (
    <React.Fragment>
        <Route path={SECTION_PATHS.CARD} component={Nanimator}/>
    </React.Fragment>
);
