import {BaseState} from './types';

export const baseDefaultState: BaseState<any> = {
    data: null,
    status: RequestStatusInitial,
    desc: null,
};

export const DEFAULT_PAGINATION = {
    pageSize: 10,
    current: 1,
    total: 0,
};
