import {useQuery} from '@tanstack/react-query';
import flow from 'lodash/flow';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment-timezone';

import {ConfigsAPI} from 'api/configs';
import {WFMFrontendTimezones} from 'types/api/backend-py3/definitions/configs';

export function useTimezones() {
    return useQuery({
        queryKey: ['resources.timezones'],

        queryFn: () => {
            return ConfigsAPI.request(['WFM_FRONTEND_TIMEZONES'])
                .then(response => {
                    if (!response.data.configs) {
                        return undefined;
                    }

                    const config = response.data.configs[0] as WFMFrontendTimezones;

                    return config.body;
                });
        },
        gcTime: Infinity,
        refetchOnMount: false,
    });
}

const PREPARED_VALUES = [
    {value: moment.tz.guess(), label: 'LOCAL'},
];

export function useTimezonesOptions() {
    const {data} = useTimezones();

    return flow(
        (items: WFMFrontendTimezones['body']) => items.map(({value}) => {
            const offset = moment.tz(value).format('Z');

            return {
                value,
                label: `${value} (${offset})`,
                data: {offset, value},
            };
        }),
        items => sortBy(items, ['data.offset', 'data.value']),
        items => uniqBy(items, 'value'),
    )([...PREPARED_VALUES, ...(data || [])]);
}
