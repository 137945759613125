/* eslint-disable @typescript-eslint/no-explicit-any */
import {produce} from 'immer';
import get from 'lodash/get';
import set from 'lodash/set';
import {ActionType, createAction, createReducer} from 'typesafe-actions';

import {
    BasePathAction,
    ResetAction,
    UpdateAction,
    PushAction,
    RemoveIndex,
    DataReducer,
} from './types';

const defaultState: DataReducer = {
};

export const actions = {
    reset: createAction('COMMON/FORM/RESET')<ResetAction>(),
    update: createAction('COMMON/FORM/UPDATE')<UpdateAction>(),
    push: createAction('COMMON/FORM/PUSH')<PushAction>(),
    set: createAction('COMMON/FORM/SET')<BasePathAction>(),
    updateFields: createAction('COMMON/FORM/UPDATE_FIELDS')<BasePathAction>(),
    remove: createAction('COMMON/FORM/REMOVE')<BasePathAction['path']>(),
    removeIndex: createAction('COMMON/FORM/REMOVE_INDEX')<RemoveIndex>(),
};

const makePath = (path: string | undefined, fieldsName: string[] = []) =>
    `${path}.${fieldsName.join('.')}`;

const reducer = createReducer<typeof defaultState, ActionType<typeof actions>>(defaultState)
    .handleAction(
        actions.update,
        (state, action) => produce(state, draft => {
            const {payload: {path, value}} = action;
            const prevValue = get(draft, path);

            if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
                set(draft, path, {...(prevValue || {}), ...value});
            } else {
                set(draft, path, value);
            }
        }),
    )
    .handleAction(
        actions.reset,
        (state, action) => produce(state, draft => {
            draft[action.payload.model] = action.payload.data;
        }),
    )
    .handleAction(
        actions.set,
        (state, action) => produce(state, draft => {
            const {payload: {path, info}} = action;

            (info || []).forEach(item => {
                if (item.touched) {
                    set(draft, makePath(path, ['_internal', 'meta', 'hasChanges']), item.touched);
                }
                set(draft, makePath(path, item.name as string[]), item.value);
            });
            return draft;
        }),
    )
    .handleAction(
        actions.updateFields,
        (state, action) => produce(state, draft => {
            const {payload: {path, info}} = action;

            (info || []).forEach(item => {
                set(draft, makePath(path, item.name as string[]), item.value);
            });

            set(draft, makePath(path, ['_internal', 'meta', 'hasChanges']), true);

            return draft;
        }),
    )
    .handleAction(
        actions.remove,
        (state, action) => produce(state, draft => {
            const {payload} = action;

            delete draft[payload];
            return draft;
        }),
    )
    .handleAction(
        actions.push,
        (state, action) => produce(state, draft => {
            const {payload: {path, value}} = action;
            const oldArray = get(draft, path);

            set(draft, path, (oldArray || []).concat(value));
        }),
    )
    .handleAction(
        actions.removeIndex,
        (state, action) => produce(state, draft => {
            const {payload: {path, index}} = action;
            const array = get(draft, path).slice();

            array.splice(index, 1);
            set(draft, path, array);
        }),
    );

export default reducer;
