import {stringify} from 'qs';
import React, {FC, useCallback, memo} from 'react';
import {useSelector} from 'react-redux';

import Info from 'components/Menu/Content/Footer/Account/Info';
import {blackboxSelector} from 'selectors/common/meta';
import {getCookie} from 'utils/cookie';
import {triggerLink} from 'utils/file';
import i18n from 'utils/localization';

import {
    AccountButton,
    Wrapper,
} from '../styles';
import {usePassportHost} from '../usePassportHost';

const Content: FC = () => {
    const blackbox = useSelector(blackboxSelector);
    const passportHost = usePassportHost();

    const profileUrl = `https://${passportHost}/profile`;
    const handleLogout = useCallback(() => {
        if (!blackbox?.uid?.value) {
            throw new Error('no uid');
        }

        const query = stringify({
            mode: 'embeddedauth',
            action: 'logout',
            yu: getCookie('yandexuid'),
            uid: blackbox.uid.value,
            origin: 'passport_profile_head_logout',
            retpath: window.location.href,
        });

        triggerLink(`https://${passportHost}/passport?${query}`, {target: '_self'});
    }, [passportHost, blackbox?.uid?.value]);

    if (!blackbox) {
        return null;
    }

    return (
        <Wrapper>
            <Info
                handleClick={() => triggerLink(profileUrl, {target: '_blank'})}
                nameMaxLength={36}
                loginMaxLength={48}
            />

            <AccountButton
                type="text"
                onClick={() => navigator.clipboard.writeText(blackbox?.uid?.value || 'no uid')}
            >
                {i18n.print('components.menu.copy_uid')}
            </AccountButton>
            <AccountButton
                danger
                type="text"
                onClick={handleLogout}
            >
                {i18n.print('components.menu.exit')}
            </AccountButton>
        </Wrapper>
    );
};

export default memo(Content);
